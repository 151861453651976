@import '../../../styles/mixins/global.scss';

.getStartedSection {
    background: url("../../../assets/Images/get-start-vectore.png"), $blue-color-100 ;
    padding: 83px 0;

    @include breakpoint("max-sm") {
        padding: 44px 0 50px 0;
    }

    .pageTitle {
        padding: 0 0 map-get($pixel, md) * 6 0;

        @include breakpoint("max-sm") {
            padding: 0 0 54px 0;
        }

        h1 {
            @include text-5xl;
            @include text-gradient;
            @include text-center;
            margin: 0;

            @include breakpoint("max-sm") {
                @include heading-small-size;
                max-width: 250px;
                margin: 0 auto;
            }
        }
    }

    .boxCenterAlignment {
        @include flex-center;
        justify-content: map-get($layout, center);

        .getStartBox {
            width: 685px;
            max-width: 685px;

            .emailInput {
                padding: 0 0 25px 0;
            }

            .submitForm {
                @include flex-center;
                justify-content: map-get($layout, center);

                button {
                    min-width: 170px;
                    height: 44px;
                    border: none;
                    @include pointer;
                    background-color: $dark-black;
                    color: $white-color;
                    @include text-md-bold;
                    line-height: auto;
                    @include ease-in-out;
                    line-height: normal;
                    border-radius: 9999px;

                    &:hover {
                        @include ease-in-out;
                        background-color: $orange-light-color;
                        color: $white-color;
                    }

                    @include breakpoint("max-sm") {}
                }
            }

            .firstInputGrid {
                padding: 0 0 25px 0;
                @include grid-layout;
                grid-template-columns: repeat(2, 1fr);
                gap: 0 map-get($pixel, sm) * 3;

                @include breakpoint("max-sm") {
                    grid-template-columns: repeat(1, 1fr);
                    gap: 25px;
                }
            }
        }
    }
}