@import '../../../styles/mixins/global.scss';
@import '../../../styles/mixins/breakpoint';


.footer {
    background-color: $black-1;
    padding: 100px 0 25px 0;

    @include breakpoint("max-md") {
        padding: 80px 0 25px 0;
    }

    @include breakpoint("max-sm") {
        padding: 70px 0 50px 0;
    }

    .copyrightSection {
        @include flex-between;

        @include breakpoint("max-sm") {
            display: block;
        }

        p {
            @include text-md;
            color: $white-color;
            @include pointer;
            @include font-normal;

            @include breakpoint("max-xl") {
                @include text;
            }
        }

        @include breakpoint("max-sm") {
            p:first-child {
                margin: 0 0 12px 0;
            }
        }
    }

    .footerGrid {
        @include grid-layout;
        grid-template-columns: 380px minmax(0, 1fr);
        gap: 0 160px;
        padding: 0 0 map-get($pixel, md) * 12 0;

        @include breakpoint("max-xl") {
            padding: 0 0 60px 0;
            grid-template-columns: 320px minmax(0, 1fr);
            gap: 60px;
        }

        @include breakpoint("max-md") {
            grid-template-columns: repeat(1, 1fr);
            gap: 100px;
        }

        @include breakpoint("max-sm") {
            padding: 0 0 40px 0;
            gap: 50px;
        }

        .footerGridItems {
            .footerSubMenuAlign {
                display: flex;
                justify-content: space-between;

                @include breakpoint("max-md") {
                    @include grid-layout;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 40px;
                }

                @include breakpoint("max-sm") {
                    @include grid-layout;
                    grid-template-columns: repeat(1, 1fr);
                    gap: 50px;
                }
            }

            .socialMedia {
                @include flex-center;

                div {
                    @include pointer;
                    margin-right: 10px;

                    &:hover {
                        img {
                            animation: jackInTheBox 1s;
                            @include ease-in-out;
                        }
                    }
                }

                img {
                    @include pointer;
                    @include ease-in-out;
                }

                div:last-child {
                    margin: 0;
                }
            }

            .getInTouch {
                label {
                    @include text-xl;
                    color: $white-color;
                    display: block;
                    padding: 0 0 15px 0;
                }

                .relativeDiv {
                    position: relative;
                    margin: 0 0 27px 0;

                    input {
                        width: 100%;
                        border: none;
                        border-radius: 6px;
                        height: 54px;
                        background-color: $white-color;
                        @include text;
                        color: $light-gray-200;
                        padding: 0 130px 0 14px;
                    }

                    .signUpButton {
                        position: absolute;
                        top: 0;
                        right: -2px;

                        button {
                            background-color: $blue-color;
                            min-width: 115px;
                            padding: 4px 10px;
                            color: $white-color;
                            border: none;
                            height: 54px;
                            min-width: 115px;
                            @include text-sm;
                            letter-spacing: 0.26px;
                            @include font-bold;
                            @include pointer;
                            border-radius: 0 6px 6px 0;
                            @include ease-in-out;

                            &:hover {
                                transition: .3s ease-in-out;
                                background-color: $orange-light-color;
                                color: $white-color;
                            }
                        }
                    }

                    ::placeholder {
                        @include text;
                        color: $light-gray-200;
                    }
                }
            }

            .footerLogo {
                img {
                    @include pointer;
                    margin: 0 0 30px 0;

                    @include breakpoint("max-xl") {
                        max-width: 155px;
                    }
                }

            }

            h2 {
                @include heading-3;
                color: $white-color;
                margin: 0 0 12px 0;

                @include breakpoint("max-xl") {
                    font-size: 22px !important;
                    line-height: 32px !important;
                }
            }

            ul {
                margin: 0;
                list-style: none;
                padding: 0;

                a {
                    padding: 0 0 map-get($pixel, sm) * 3 + 1px 0;
                    display: block;
                }

                li:last-child {
                    padding: 0;
                }

                li {
                    @include text-md;
                    color: $white-color;
                    font-size: 16px;
                    @include pointer;
                    letter-spacing: 0.16px;
                    @include font-normal;
                    @include ease-in-out;

                    @include breakpoint("max-xl") {
                        @include text-sm;
                        font-size: 14px;
                        line-height: 20px;
                    }

                    &:hover {
                        @include top-up;
                    }
                }
            }
        }
    }
}