@import '../../../styles/mixins/global.scss';

.bringSolutionsSection {
    padding: 100px 0;

    @include breakpoint("max-xl") {
        padding: 80px 0;
    }

    @include breakpoint("max-sm") {
        padding: 65px 0 82px 0;
    }

    .pageTitle {
        padding: 0 0 60px 0;

        @include breakpoint("max-sm") {
            padding: 0 0 52px 0;
        }

        h1 {
            @include text-5xl;
            color: $black-2;
            @include text-center;
            max-width: calc(460px);
            line-height: 50px;
            margin: 0 auto;
            @include text-center;

            @include breakpoint("max-sm") {
                @include heading-3;
                max-width: calc(255px);
                @include text-center;
            }

            a {
                @include text-gradient;
            }
        }
    }

    .grid {
        @include grid-layout;
        grid-template-columns: 400px minmax(0, 1fr);
        gap: 0 111px;
        align-items: center;

        @include breakpoint("max-xl") {
            grid-template-columns: 387px minmax(0, 1fr);
            gap: 0 80px;
        }

        @include breakpoint("max-md") {
            grid-template-columns: repeat(1, 1fr);
            gap: 40px
        }

        @include breakpoint("max-sm") {
            grid-template-columns: repeat(1, 1fr);
            gap: 30px;
        }

        .gridItems:nth-child(1) {
            @include breakpoint("max-sm") {
                order: 2;
            }
        }
    }
}

.bringSolutionsSectionTextstyle {
    h2 {
        @include heading-6;
        color: $black-2;
        margin: 0 0 10px 0;

        @include breakpoint("max-xl") {
            @include heading-small-size;
        }

        @include breakpoint("max-sm") {
            @include text-2xl-bold;
            @include text-center;
            margin: 0 0 8px 0;
        }
    }

    p {
        @include text-20xl;
        color: $black-2;

        @include breakpoint("max-sm") {
            @include text-md-regular;
            @include text-center;
            padding: 0 30px;
        }
    }
}

.proactiveSection {
    .grid {
        @include grid-layout;
        grid-template-columns: minmax(0, 1fr) 400px;
        gap: 0 134px;
        align-items: center;

        @include breakpoint("max-xl") {
            grid-template-columns: minmax(0, 1fr) 387px;
            gap: 0 80px;
        }

        @include breakpoint("max-md") {
            grid-template-columns: repeat(1, 1fr);
            gap: 40px
        }

        @include breakpoint("max-sm") {
            grid-template-columns: repeat(1, 1fr);
            gap: 30px
        }

        .gridItems {}
    }
}

.invaluableSection {
    padding: 120px 0;

    @include breakpoint("max-xl") {
        padding: 90px 0;
    }

    @include breakpoint("max-sm") {
        padding: 82px 0;
    }

    .grid {
        @include grid-layout;
        grid-template-columns: 400px minmax(0, 1fr);
        align-items: center;
        gap: 0 157px;

        @include breakpoint("max-xl") {
            grid-template-columns: 387px minmax(0, 1fr);
            gap: 0 80px;
        }

        @include breakpoint("max-md") {
            grid-template-columns: repeat(1, 1fr);
            gap: 40px
        }

        @include breakpoint("max-sm") {
            grid-template-columns: repeat(1, 1fr);
            gap: 30px
        }

        .gridItems:nth-child(1) {
            @include breakpoint("max-sm") {
                order: 2;
            }
        }
    }
}