.PaymentInstantly{
    min-height: 200px;
    min-width: 200px;
    height: 100%;
    width: 100%;
    max-width: 800px;
    position: relative;
    margin-bottom: 40px;
    img{
        max-height: 300px;
        @media(min-width: 350px){
            max-height: 10000px;
            max-width: 60%;
        }
        animation-delay: 2s;
        &:nth-child(1){
            animation: 1.5s slide-up s forwards;
        }
        &:nth-child(2){
            animation: slide-up 2s forwards;
            position: absolute;
            left: 38%;
            bottom: -10%;
            @media(min-width: 865px){
                left: 38%;
                bottom: -8%;
            }
        }
    }
}

@keyframes slide-up{
    0%{
        opacity: 0.3;
        transform: translateY(5%);
    }
    100%{
        opacity: 1;
        transform: translateY(0%);
    }
}