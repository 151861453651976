@import '../../../styles/mixins/global.scss';

.businessKitSection {
    padding: 120px 0;

    @include breakpoint("max-xl") {
        padding: 80px 0;
    }

    @include breakpoint("max-md") {
        padding: 80px 0;
    }

    @include breakpoint("max-sm") {
        padding: 65px 0;
    }

    img[alt="PayIcon"] {
        height: 35px;
    }

    .pageTitle {
        padding: 0 0 map-get($pixel, md) * 8 0;

        @include breakpoint("max-sm") {
            padding: 0 0 30px 0;
        }

        h1 {
            @include text-5xl;
            margin: 0;
            max-width: 410px;
            color: $black-1;

            @include breakpoint("max-xl") {
                @include heading-small-size;
                max-width: 350px;
            }

            @include breakpoint("max-sm") {
                @include heading-6;
                max-width: 250px;
            }

            a {
                @include text-gradient;
            }
        }
    }

    .grid {
        @include grid-layout;
        grid-template-columns: repeat(3, 1fr);
        gap: 50px 20px;

        @include breakpoint("max-xl") {
            gap: 30px 20px;
        }

        @include breakpoint("max-md") {
            grid-template-columns: repeat(1, 1fr);
            gap: 40px;
        }

        .gridItems {
            background-color: $bg-light-200;
            border-radius: map-get($pixel, md);
            padding: 32px;
            padding-right: 17px !important;
            cursor: pointer;
            @include ease-in-out;

            @include breakpoint("max-xl") {
                padding: 20px !important;
            }

            &:hover {
                @include top-up;
            }

            @include breakpoint("max-sm") {
                padding: 0 !important;
            }

            .subGrid {
                @include grid-layout;
                grid-template-columns: 177px minmax(0, 1fr);
                gap: 0 20px;

                @include breakpoint("max-sm") {
                    grid-template-columns: repeat(1, 1fr);
                }

                .subGridItems:nth-child(2) {
                    @include breakpoint("max-sm") {
                        padding: 23px;
                    }
                }

                .subGridItems:nth-child(2):hover img {
                    animation: jackInTheBox 1s;

                    @include breakpoint("max-sm") {
                        animation: none;
                    }

                }

                .subGridItems {
                    .chainIcon {
                        padding: 0 0 map-get($pixel, md) + 4px 0;

                        img {
                            transition: .3s ease-in-out;
                            height: 33px;
                        }

                    }

                    h2 {
                        @include heading-3xl;
                        color: $blue-color;
                        margin: 0 0 map-get($pixel, md) 0;

                        @include breakpoint("max-xl") {
                            font-size: 20px;
                            line-height: 28px;
                        }

                        @include breakpoint("max-sm") {
                            font-size: 25px;
                            line-height: 30px;
                        }
                    }

                    p {
                        @include text;
                        line-height: 1.63;
                        letter-spacing: -0.36px;
                        opacity: 0.73;
                        @include font-normal;
                        color: $black-1;

                        @include breakpoint("max-xl") {
                            font-size: 14px;

                        }

                        @include breakpoint("max-sm") {
                            font-size: 18px;
                        }
                    }

                    .cardImage {
                        img {
                            width: 100%;
                            height: 281px;
                            border-radius: 10px;
                            object-fit: cover;

                            @include breakpoint("max-xl") {
                                height: 235px;
                            }

                            @include breakpoint("max-sm") {
                                height: 181px;
                                object-position: center;
                            }
                        }

                        img:first-child {
                            @include breakpoint("max-sm") {
                                display: none;
                            }
                        }

                        img:last-child {
                            display: none;

                            @include breakpoint("max-sm") {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
}