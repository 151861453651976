@import '../../../styles/mixins/global.scss';

.fullLayerImageAlignment {
    position: relative;

    img:first-child {
        @include breakpoint("max-sm") {
            display: none;
        }
    }

    img:last-child {
        display: none;

        @include breakpoint("max-sm") {
            display: block;
        }
    }
}

.scaleSectionAlignment {
    background-color: $bg-white-light-color;
    padding: 283px 0 130px 0;
    margin-top: -190px;

    @include breakpoint("max-sm") {
        padding: 205px 0 60px 0;
    }

    .textGrid {
        @include grid-layout;
        grid-template-columns: minmax(0, 1fr) 504px;
        gap: 0 86px;
        align-items: map-get($layout, center);

        @include breakpoint("max-md") {
            grid-template-columns: repeat(1, 1fr);
            gap: 20px;
        }

        .textGridItems {
            h1 {
                @include text-5xl;
                color: $black-1-light;
                max-width: calc(380px);
                margin: 0;

                @include breakpoint("max-xl") {
                    @include heading-small-size;
                    max-width: 355px;
                }

                @include breakpoint("max-sm") {
                    @include heading-6;
                    max-width: 266px;
                }
            }

            p {
                @include text-xl-normal;
                color: $black-1-light;
                margin: 0;
                letter-spacing: 0px;
                line-height: 36px;

                @include breakpoint("max-xl") {
                    max-width: 473px;
                }

                @include breakpoint("max-sm") {
                    @include text;
                    letter-spacing: 0px;
                    max-width: 295px;
                }
            }
        }
    }
}