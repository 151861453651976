@import '../../../styles/mixins/global.scss';


.whiteHeader {
    padding: map-get($pixel, md) * 2 + 8px 0;
    position: relative;
    transition: .3s ease-in-out;

    @include breakpoint("max-xl") {
        padding: 22px 0;
    }

    @include breakpoint("max-sm") {
        height: auto;
    }

    .headerAlignment {
        @include flex-between;

        .logo {
            h1 {
                @include heading-large;
                user-select: none;
                @include pointer;
                color: $white-color;

                @include breakpoint("max-sm") {
                    font-size: 30px;
                }
            }

            img {
                @include pointer;
                max-width: 150px;

                @include breakpoint("max-xl") {
                    max-width: 155px;
                }
            }
        }

        .mobileMenu {
            display: none;

            @include breakpoint("max-md") {
                display: block;
            }
        }

        .menu {
            @include breakpoint("max-md") {
                display: none;
            }

            nav {
                ul {
                    @include flex-center;
                    margin: 0;
                    padding: 0;

                    li:last-child {
                        margin: 0;
                    }

                    li {
                        color: $white-color;
                        @include text-sm;
                        @include font-medium;
                        text-transform: uppercase;
                        margin-right: 56px;
                        @include pointer;

                        @include breakpoint("max-xl") {
                            font-size: 14px;
                            line-height: 20px;
                            font-family: 'Sofia-Medium';
                            font-weight: 500;
                            margin-right: 40px;
                        }

                        span {
                            padding-right: map-get($pixel, sm) * 2;
                        }

                        i {
                            color: $white-color;
                        }
                    }
                }
            }
        }
    }
}

.mobileSidebarWrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    z-index: 99999;
}

.mobileViewSidebar {
    position: fixed;
    top: 0;
    right: 0;
    width: 375px;
    height: 100vh;
    z-index: 9999999;
    background-color: $blue-color-100;

    @include breakpoint("max-sm") {
        width: 100%;
    }

    .mobileHeader {
        padding: 30px;
        @include flex-center;
        justify-content: space-between;

        div {
            img {
                @include pointer;
            }
        }
    }

    .mobileBody {
        padding: 0 30px;

        .leftSideAlignment {
            padding-left: 25px;
        }

        a {
            @include text-md;
            letter-spacing: 0px;
            display: block;
            padding: 0 0 25px 0;
            @include pointer;
            color: $white-color;
        }
    }
}

.sidebarHidden {
    transform: translateX(100%);
    transition: .3s ease-in-out;
}

.sidebarShow {
    transform: translateX(0%);
    transition: .3s ease-in-out;
}