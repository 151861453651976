@import '../../../styles/mixins/global.scss';

.processTypeAlignment {
    padding: 100px 0;

    @include breakpoint("max-xl") {
        padding: 80px 0;
    }

    @include breakpoint("max-md") {
        padding: 80px 0;
    }

    @include breakpoint("max-sm") {
        padding: 51px 0 91px 0;
    }

    .grid {
        @include grid-layout;
        grid-template-columns: 571px minmax(0, 1fr);
        gap: 0 185px;
        align-items: center;

        @include breakpoint("max-md") {
            grid-template-columns: repeat(1, 1fr);
            gap: 60px;
        }

        @include breakpoint("max-sm") {
            grid-template-columns: repeat(1, 1fr);
            gap: 24px;
        }

        .gridItems {
            p {
                max-width: 451px;

                @include breakpoint("max-xl") {
                    max-width: 410px;
                }

                @include breakpoint("max-sm") {
                    max-width: 440px;
                }
            }

            h1 {
                max-width: calc(374px);
            }
        }
    }
}

.blockchainProcessTypeText {
    h1 {
        @include heading-6;
        color: $black-2;
        margin: 0 0 18px 0;

        @include breakpoint("max-xl") {
            @include heading-small-size;
        }

        @include breakpoint("max-sm") {
            @include text-2xl-bold;
            margin: 0 0 10px 0;
        }
    }

    p {
        @include text-20xl;
        color: $black-2;

        @include breakpoint("max-sm") {
            line-height: 30px;
            letter-spacing: -0.44px;
        }
    }
}

.utilizeWorldWallet {
    .grid {
        @include grid-layout;
        grid-template-columns: minmax(0, 1fr) 611px;
        gap: 0 150px;

        @include breakpoint("max-xl") {
            gap: 0 80px;
            grid-template-columns: minmax(0, 1fr) 550px;
        }

        @include breakpoint("max-md") {
            grid-template-columns: repeat(1, 1fr);
            gap: 60px;
        }

        @include breakpoint("max-sm") {
            grid-template-columns: repeat(1, 1fr);
            gap: 24px;
        }

        .gridItems:nth-child(1) {
            @include breakpoint("max-sm") {
                order: 2;
            }
        }

        .gridItems {
            h1 {
                max-width: calc(403px);
            }

            p {
                max-width: calc(500px);

                @include breakpoint("max-xl") {
                    max-width: calc(474px);
                }

                @include breakpoint("max-sm") {
                    max-width: calc(310px);
                }
            }
        }
    }
}

.directlyFromLive {
    padding: 120px 0;

    @include breakpoint("max-md") {
        padding: 80px 0;
    }

    @include breakpoint("max-sm") {
        padding: 24px 0 115px 0;
    }

    .grid {
        @include grid-layout;
        grid-template-columns: 598px minmax(0, 1fr);
        gap: 0 158px;
        align-items: center;

        @include breakpoint("max-xl") {
            gap: 0 97px;
        }

        @include breakpoint("max-md") {
            grid-template-columns: repeat(1, 1fr);
            gap: 60px;
        }

        @include breakpoint("max-sm") {
            grid-template-columns: repeat(1, 1fr);
            gap: 24px;
        }

        .gridItems {
            h1 {
                max-width: 465px;

            }

            p {
                max-width: calc(500px);

                @include breakpoint("max-sm") {
                    max-width: calc(310px);
                }
            }
        }
    }
}