@import '../../../styles/mixins/global.scss';

.automatedSolutionsSection {
    padding: map-get($pixel, md) * 10 0;
    background-color: $bg-light;

    @include breakpoint("max-xl") {
        padding: 80px 0;
    }

    @include breakpoint("max-md") {
        padding: map-get($pixel, md) * 8 0;
    }

    @include breakpoint("max-sm") {
        padding: map-get($pixel, md) * 6 0;
    }

    .grid {
        @include grid-layout;
        grid-template-columns: minmax(0, 1fr) 490px;
        gap: 0 90px;
        align-items: center;

        @include breakpoint("max-md") {
            grid-template-columns: repeat(1, 1fr);
            gap: 60px;
        }

        @include breakpoint("max-sm") {
            gap: 24px;
        }

        .gridItems {
            max-width: 100%;

            img {
                max-width: 100%;
                height: 462px;

                @include breakpoint("max-sm") {
                    height: 222px;
                }
            }

            h3 {
                margin: 0 0 map-get($pixel, sm) * 2 + 2px 0;
                @include heading-3xl;
                color: $black-1;

                @include breakpoint("max-sm") {
                    @include text-2xl;
                    margin: 0 0 8px 0;
                    @include font-bold;
                }
            }

            p {
                @include text-xl-normal;
                color: $black-1;
                line-height: 1.7;
                letter-spacing: -0.44px;

                @include breakpoint("max-xl") {
                    max-width: 427px;
                }

                @include breakpoint("max-sm") {
                    @include text-xl-normal;
                }
            }
        }
    }

    .pageTitle {
        max-width: 631px;
        padding: 0 0 map-get($pixel, md) * 5 0;

        @include breakpoint("max-sm") {
            padding: 0 0 24px 0;
        }

        h1 {
            margin: 0 0 map-get($pixel, md) * 4 - 2px 0;
            @include text-5xl;
            color: $black-1;
            max-width: 470px;

            @include breakpoint("max-xl") {
                @include heading-small-size;
                max-width: 440px;
            }

            @include breakpoint("max-sm") {
                @include heading-6;
                padding-right: 10px;
                max-width: 260px;
                margin: 0 0 36px 0;
            }

            a {
                @include text-gradient;
            }
        }

        .tab {
            @include flex-center;

            @include breakpoint("max-sm") {
                overflow: scroll;
                white-space: nowrap;
                padding: 0 0 10px 0;
            }

            .activeButton {
                border: 2px solid $blue-color;
                color: $blue-color;
            }


            button {
                height: 44px;
                padding: 0px 28px;
                color: $black-2;
                border: 2px solid transparent;
                @include text-2xl;
                font-size: 18px;
                @include pointer;
                background-color: transparent;
                border-radius: 9999px;
                margin-right: 10px;
                transition: .3s ease-in-out;

                @include breakpoint("max-xl") {
                    padding: 0 20px;
                    @include text-md;
                }

                @include breakpoint("max-sm") {
                    height: 40px;
                    margin: 0;
                    @include text-md;
                    @include font-bold;
                }

                &:hover {
                    border: 2px solid $blue-color;
                    color: $blue-color;
                }

            }
        }
    }
}