@import '../../../styles/mixins/global.scss';

.blockchainEcommerceAlign {
    padding: 100px 0;

    @include breakpoint("max-xl") {
        padding: 80px 0;
    }

    @include breakpoint("max-sm") {
        padding: 67px 0 84px 0;
    }

    .grid {
        @include grid-layout;
        grid-template-columns: 672px minmax(0, 1fr);
        gap: 0 147px;
        align-items: center;

        @include breakpoint("max-xl") {
            grid-template-columns: 610px minmax(0, 1fr);
        }

        @include breakpoint("max-md") {
            grid-template-columns: repeat(1, 1fr);
            gap: 60px;
        }

        @include breakpoint("max-sm") {
            grid-template-columns: repeat(1, 1fr);
            gap: 40px;
        }

        .gridItems {
            h1 {
                @include heading-3-xl;
                margin: 0 0 18px 0;
                max-width: calc(374px);
                color: $black-2;

                @include breakpoint("max-sm") {
                    @include text-2xl-bold;
                    max-width: 250px;
                    margin: 0 0 10px 0;
                }
            }

            p {
                @include text-20xl;
                color: $black-2;
                letter-spacing: 0px;
                line-height: 30px;
                max-width: calc(440px);
            }

            img {
                width: 100%;

            }
        }
    }
}

.anewMintingProcess {
    .subGrid {
        @include grid-layout;
        grid-template-columns: 550px minmax(0, 1fr);
        gap: 0 110px;
        align-items: center;

        @include breakpoint("max-md") {
            grid-template-columns: repeat(1, 1fr);
            gap: 60px;
        }

        @include breakpoint("max-sm") {
            grid-template-columns: repeat(1, 1fr);
            gap: 40px;
        }

        .subGridItems:nth-child(1) {
            padding-left: 50px;

            @include breakpoint("max-sm") {
                order: 2;
                padding: 0;
            }
        }

        .subGridItems {
            h1 {
                @include heading-3-xl;
                margin: 0 0 18px 0;
                color: $black-2;

                @include breakpoint("max-sm") {
                    @include text-2xl-bold;
                    margin: 0 0 10px 0;
                }
            }

            p {
                @include text-20xl;
                color: $black-2;
                letter-spacing: 0px;
                line-height: 30px;

                @include breakpoint("max-xl") {
                    max-width: 468px;
                }
            }
        }
    }
}


.digitalWalletsSectionAlignent {
    padding: 120px 0;

    @include breakpoint("max-xl") {
        padding: 90px 0;
    }

    @include breakpoint("max-sm") {
        padding: 93px 0;
    }

    .grid {
        @include grid-layout;
        grid-template-columns: 672px minmax(0, 1fr);
        gap: 0 145px;
        align-items: center;

        @include breakpoint("max-xl") {
            grid-template-columns: 610px minmax(0, 1fr);
        }

        @include breakpoint("max-md") {
            grid-template-columns: repeat(1, 1fr);
            gap: 60px;
        }

        @include breakpoint("max-sm") {
            grid-template-columns: repeat(1, 1fr);
            gap: 40px;
        }

        .gridItems {
            h1 {
                @include heading-3-xl;
                margin: 0 0 18px 0;
                max-width: calc(374px);
                color: $black-2;

                @include breakpoint("max-sm") {
                    @include text-2xl-bold;
                    margin: 0 0 10px 0;
                }
            }

            p {
                @include text-20xl;
                color: $black-2;
                letter-spacing: 0px;
                line-height: 30px;
                max-width: calc(440px);
            }

            img {
                max-width: 100%;
                width: 100%;
            }
        }
    }
}