@import '../../../styles/mixins/global.scss';

.partnersSaySection {
    padding: 0 0 180px 0;
    @include breakpoint("max-xl") {
        padding: 0 0 120px 0;
    }
    @include breakpoint("max-md") {
        padding: 0 0 80px 0;
    }
    @include breakpoint("max-sm") {
        padding: 0 0 120px 0;
    }
   
    .pageTitle {
        max-width: 420px;
        @include breakpoint("max-xl") {
            max-width: 325px;
        }
        h1 {
            @include text-5xl;
            color: $black-1;
            max-width: 360px;
            margin: 0 0 30px 0;
            @include breakpoint("max-xl") {
                @include heading-small-size;
            }
            @include breakpoint("max-sm") {
                max-width: 285px;
            }
            a {
                @include text-gradient;
            }
        }
    }
    .partnersBox {
        background-image: linear-gradient(120deg, #ff8d00, #ffb322 97%);
        border-radius: map-get($pixel, sm ) * 3;
        padding: 50px 37px;
        @include breakpoint("max-sm") {
            padding: 30px;
        }
        > h2 {
            color: $white-color;
            padding-left: 18px;
            margin: 0 0 map-get($pixel, md ) * 6 + 4px 0;
            @include heading-3xl;
            display: -webkit-box;
            font-family: 'Sofia-Medium';
            letter-spacing: -0.51px;
            font-weight: 500;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;  
            overflow: hidden;
            @include breakpoint("max-sm") {
                @include text-2xl;
                margin: 0 0 18px 0;
                @include font-medium;
            }
        }
        .profileGrid {
            @include grid-layout;
            grid-template-columns: 84px minmax(0 , 1fr);
            gap: 0 map-get($pixel, md ) * 1 + 5px;
            align-items: center;
            @include breakpoint("max-sm") {
                grid-template-columns: 40px minmax(0 , 1fr);
                gap: 0 16px;
            }
            .profileGridItems {
                img {
                    width: 84px;
                    height: 84px;
                    border-radius: 50%;
                    object-fit: cover;
                    @include breakpoint("max-sm") {
                        height: 40px;
                        width: 40px;
                    }
                }
                h2 {
                    margin: 0;
                    @include heading-3xl;
                    color: $white-color;
                    @include breakpoint("max-sm") {
                        @include text-sm;

                    }
                 
                }
                p {
                    @include text-3xl;
                    font-size: 20px;
                    color: $white-color;
                    @include breakpoint("max-sm") {
                        @include text;
                    }
                   
                }
            }
        }
    }
    
    .partnersSlider {
        :global(.slick-slide) {
            padding: 0 20px;
            @include breakpoint("max-sm") {
                padding: 0 6px;
            }
        }
        :global(.slick-dots li button:before) {
            font-size: 20px;
        }
        :global(.slick-dots li.slick-active button:before) {
            color: $orange-color;
            opacity: 1;
        }
        :global(.slick-dots li button:before ){
            opacity: 0.42;
            color: $orange-color;
        }
        :global(.slick-dots ) {
            bottom: -70px;
            @include breakpoint("max-sm") {
                bottom: -46px;
                text-align: left;
            }
        }
        :global(.slick-list ) {
            margin: 0 -25px;
            @include breakpoint("max-sm") {
                margin: 0 -5px;
            }
        }
    }

}