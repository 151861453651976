@import '../../../styles/mixins/global.scss';

.informedDecisionsBanner {
    background: url("../../../assets/Images/new-3d-vectore.png");
    padding: 94px 0;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;

    @include breakpoint("max-sm") {
        padding: 74px 0 96px 0;
    }

    .cardAlignment {
        padding: 0 160px;

        @include breakpoint("max-xl") {
            padding: 0 88px;
        }

        @include breakpoint("max-md") {
            padding: 0 0;
        }

        .card {
            @include grid-layout;
            grid-template-columns: repeat(12, 1fr);
            gap: 86px;

            @include breakpoint("max-md") {
                gap: 40px;
            }

            @include breakpoint("max-sm") {
                grid-template-columns: repeat(1, 1fr);
                gap: 36px;
                padding: 0 40px;
            }

            .cardItems:first-child {
                p {
                    padding: 10px;

                    @include breakpoint("max-xl") {
                        padding: 0;
                    }

                    @include breakpoint("max-md") {
                        padding: 0;
                    }

                    @include breakpoint("max-sm") {
                        padding: 0 49px;
                    }
                }
            }

            .cardItems:nth-child(2) {
                p {
                    padding: 30px - 20px;

                    @include breakpoint("max-xl") {
                        padding: 0;
                    }

                    @include breakpoint("max-md") {
                        padding: 0;
                    }

                    @include breakpoint("max-sm") {
                        padding: 0 32px;

                    }
                }
            }

            .cardItems:last-child {
                p {

                    @include breakpoint("max-md") {
                        padding: 0;
                    }

                    @include breakpoint("max-sm") {
                        padding: 0 40px;
                    }
                }
            }

            .cardItems {
                grid-column: span 4;
                background-color: #4198e7;
                border-radius: 30px;
                padding: 43px 28px;

                @include breakpoint("max-md") {
                    grid-column: span 6;
                }

                @include breakpoint("max-sm") {
                    grid-column: auto;
                    padding: 9px 0 31px 0;
                }

                h1 {
                    font-size: 60px;
                    font-family: 'Sofia-Bold';
                    margin: 0 0 6px 0;
                    color: $white-color;
                    @include text-center;
                    @include font-bold;
                    letter-spacing: -1.33px;

                    @include breakpoint("max-sm") {
                        line-height: 88px;
                    }
                }

                p {
                    @include text-2xl;
                    color: $white-color;
                    @include text-center;

                    @include breakpoint("max-xl") {
                        font-size: 20px;
                        line-height: 30px;
                    }

                    @include breakpoint("max-sm") {
                        @include text-2xl;
                        line-height: 30px !important;
                    }
                }
            }
        }
    }

    .pageTitle {
        padding: 0 0 map-get($pixel, md) * 8 0;

        @include breakpoint("max-xl") {
            padding: 0 0 60px 0;
        }

        @include breakpoint("max-sm") {
            padding: 0 0 46px 0;
        }

        h1 {
            max-width: 775px;
            color: $white-color;
            margin: 0 auto;
            @include text-center;
            @include heading-lg;
            line-height: 61px;
            font-family: 'Sofia-Medium';
            font-weight: 500;

            @include breakpoint("max-xl") {
                max-width: 500px;
                @include heading-small-size;
            }

            @include breakpoint("max-sm") {
                @include heading-6;
                letter-spacing: -0.8px;
                padding: 0 58px;
            }

            a {
                @include text-gradient;
            }
        }
    }
}