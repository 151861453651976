@import '../../../styles/mixins/global.scss';

.growthAndSecurityBanner {
    background-image: url("../../../assets/Images/GrowthAndSecurity.png");
    background-repeat: no-repeat;
    padding: 190px 0;
    background-size: cover;
    width: 100%;
    bottom: -1px;
    position: relative;

    @include breakpoint("max-sm") {
        padding: 113px 0 340px 0;
        background-position: center center;
        margin-bottom: -1px;
    }

    div:first-child {
        div {
            @include breakpoint("max-sm") {
                @include flex-center;
                justify-content: center;
                padding: 45px 0 0 0;

                button {
                    min-width: 198px;
                }
            }
        }
    }

    h1 {
        @include text-5xl;
        color: $white-color;
        margin: 0 0 30px 0;
        max-width: calc(520px);
        line-height: 50px;

        @include breakpoint("max-sm") {
            @include heading-6;
            max-width: calc(246px);
            margin: 0 auto;
            @include text-center;
        }

        a {
            @include text-gradient;
        }
    }
}