@import '../../../styles/mixins/global.scss';

.notableMilestonesSection {
    padding: 100px 0;

    @include breakpoint("max-sm") {
        padding: 80px 0;
    }

    @include breakpoint("max-sm") {
        padding: 50px 0 37px 0;
    }

    .pageTitle {
        padding: 0 0 80px 0;

        @include breakpoint("max-xl") {
            padding: 0 0 80px 0;
        }

        @include breakpoint("max-sm") {
            padding: 0 0 50px 0;
        }

        h1 {
            @include text-5xl;
            color: $black-1;
            line-height: 54px;

            @include breakpoint("max-xl") {
                @include heading-small-size;
            }

            @include breakpoint("max-sm") {
                @include heading-6;
            }

            span {
                @include text-gradient;
            }
        }
    }

    .grid {
        @include grid-layout;
        grid-template-columns: repeat(4, 1fr);
        gap: 0;

        @include breakpoint("max-md") {
            grid-template-columns: repeat(1, 1fr);
        }

        @include breakpoint("max-sm") {
            grid-template-columns: repeat(1, 1fr);
        }

        .gridItems:last-child {
            padding-right: 0px;

            .relativeDiv {
                &::before {
                    content: none;
                }
            }
        }

        @include breakpoint("max-md") {
            .gridItems:last-child {
                &::before {
                    content: none;
                }
            }
        }

        @include breakpoint("max-sm") {
            .gridItems:last-child {
                &::before {
                    content: none;
                }
            }
        }

        .gridItems {
            @include breakpoint("max-md") {
                position: relative;
                display: flex;

                &::before {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 35px;
                    content: "";
                    width: 1px;
                    height: 100%;
                    border: 1px dashed $light-border;
                }
            }

            @include breakpoint("max-sm") {
                position: relative;
                display: flex;

                &::before {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 35px;
                    content: "";
                    width: 1px;
                    height: 100%;
                    border: 1px dashed $light-border;
                }
            }

            .detailsShow {
                padding-right: 57px;
                padding-top: map-get($pixel, md) + 3px;

                @include breakpoint("max-xl") {
                    padding-right: 28px;
                }

                @include breakpoint("max-md") {
                    padding-left: 40px;
                    padding-top: 0;
                    padding-right: 0px;
                }

                @include breakpoint("max-sm") {
                    padding-left: 10px;
                    padding-top: 5px;
                    padding-right: 0px;
                }

                h1 {
                    @include heading-5xl;
                    margin: 0 0 map-get($pixel, sm) * 2 0;
                    color: $blue-color-100;

                    @include breakpoint("max-xl") {
                        @include heading-small-size;
                    }
                }

                p {
                    color: $black-1;
                    @include text;
                    letter-spacing: normal;
                    max-width: 260px;

                    @include breakpoint("max-md") {
                        margin: 0 0 48px 0;
                        max-width: 100%;
                    }

                    @include breakpoint("max-sm") {
                        margin: 0 0 38px 0;
                        max-width: 215px;
                    }
                }
            }

            .relativeDiv {
                position: relative;

                &:before {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 0;
                    content: "";
                    width: 100%;
                    height: 1px;
                    border: 1px dashed $light-border;

                    @include breakpoint("max-md") {
                        content: none;
                    }

                    @include breakpoint("max-sm") {
                        content: none;
                    }
                }
            }

            .iconDesign {
                width: 68px;
                height: 68px;
                position: relative;
                @include flex-center;
                justify-content: map-get($layout, center);
                background-color: $orange-color-light;
                border-radius: 50%;
                @include pointer;

            }
        }
    }
}