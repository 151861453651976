// @import url("http://fonts.cdnfonts.com/css/sofia-pro");

@font-face {
    font-family: 'Sofia-Light';
    src: url("../../assets/font/Sofia\ Pro\ Light\ Az.otf");
}

@font-face {
    font-family: 'Sofia-Regular';
    src: url("../../assets/font/Sofia\ Pro\ Regular\ Az.otf");
}

@font-face {
    font-family: 'Sofia-Medium';
    src: url("../../assets/font/Sofia\ Pro\ Medium\ Az.otf");
}

@font-face {
    font-family: 'Sofia-Bold';
    src: url("../../assets/font/Sofia\ Pro\ Bold\ Az.otf");
}

@font-face {
    font-family: 'Fertigo_PRO';
    src: url("../../assets/font/Fertigo_PRO.otf");
}


@mixin font-normal {
    font-weight: 400;
}

@mixin font-medium {
    font-weight: 500;
}

@mixin font-bold {
    font-weight: 600;
}