@import '../../../styles/mixins/global.scss';

.paymentModalCenterAlignment {
    @include flex-center;
    padding: 120px 0;
    justify-content: center;

    button {
        padding: 12px 24px;
        background-color: $base-color;
        color: $white-color;
        border-radius: 4px;
        border: none;
        @include pointer;
        @include text;
    }
}


.modalWrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    @include flex-center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.45);
}

.modal {
    width: 828px;
    max-width: 828px;
    background-color: $white-color;
    max-height: calc(100vh - 60px);
    overflow: auto;
    overflow-x: hidden;
    border-radius: 11px;
    animation-name: tostermodal;
    animation-duration: .9s;
    animation-timing-function: linear;

    @include breakpoint("max-md") {
        width: calc(100% - 40px);
    }

    @include breakpoint("max-sm") {
        width: calc(100% - 20px);
    }

    .modalHeader {
        background-color: $modal-background-color;
        height: 33px;
        border-radius: 11px 11px 0 0;
        position: relative;
        @include flex-center;
        justify-content: center;

        .headerActionAlignment {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 14px;
            @include flex-center;
            justify-content: center;

            div {
                width: 14px;
                @include pointer;
                height: 14px;
                border-radius: 50%;
                @include flex-center;
                justify-content: center;
                margin-right: 6px;
            }

            div:last-child {
                margin: 0;
            }

            div:first-child {
                background-color: #e65353;
            }

            div:nth-child(2) {
                background-color: #e6d153;
            }

            div:last-child {
                background-color: #8de653;
            }
        }

        p {
            @include text-small;
            color: $black-3;
            margin: 0;
            @include text-center;
            margin: 0;
        }
    }
}

.paymentModalBody {
    padding: 20px 37px;

    .childMenuHidden {
        max-height: 0px;
        overflow: hidden;
        transition: .3s ease-in-out;
    }

    .childMenuShow {
        max-height: calc(100vh - 0px);
        transition: .3s ease-in-out;
        overflow: hidden;
    }

    .childMenuAlignment {
        padding: 16px 0;

        .twoInputGrid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 0 10px;

        }

        .payButton {
            button {
                background-color: $dark-black;
                color: $white-color;
                @include text-md;
                border-radius: 8px;
                width: 100%;
                border: none;
                height: 45px;
                @include pointer;
            }
        }

        .paymentInput {
            margin: 0 0 15px 0;

            label {
                @include text-sm;
                display: block;
                padding: 0 0 4px 0;
                font-family: 'Sofia-Bold';
                color: $dark-black;
            }

            input {
                width: 100%;
                height: 40px;
                background-color: $white-color;
                padding: 0 13px;
                border: 1px solid $border-color-2;
                border-radius: 7px;
                @include text-sm;
                color: $dark-black;
                transition: .3s ease-in-out;

                &:focus {
                    border: 1px solid $orange-color-4;
                }
            }
        }
    }

    .detailsShow {
        background-color: #fff9f4;
        border: solid 1px $orange-color-4  !important;
    }

    @include breakpoint("max-sm") {
        padding: 16px 15px;
    }

    /* COMMON RADIO AND CHECKBOX STYLES  */
    input[type=radio],
    input[type=checkbox] {
        /* Hide original inputs */
        visibility: hidden;
        position: absolute;
    }

    input[type=radio]+label:before,
    input[type=checkbox]+label:before {
        height: 20px;
        width: 20px;
        margin-right: 2px;
        content: " ";
        display: inline-block;
        vertical-align: baseline;
        border: 1px solid $border-color-2;
    }

    input[type=radio]:checked+label:before,
    input[type=checkbox]:checked+label:before {
        background: $base-color;
        border: 1px solid transparent;
    }

    /* CUSTOM RADIO AND CHECKBOX STYLES */
    input[type=radio]+label:before {
        border-radius: 50%;
    }

    input[type=checkbox]+label:before {
        border-radius: 2px;
    }

    h2 {
        @include text-md-bold;
        color: $dark-black;
        margin: 0;
        margin: 0 0 20px 0;
    }

    .paymentMethoadBoxAlignment {
        .optionBox {
            border: 1px solid $border-color-2;
            border-radius: 11px;
            margin: 0 0 10px 0;
            padding: 16px;

            .contentAlignment {
                @include flex-center;
                @include pointer;

                div {
                    @include flex-center;
                }

                label {
                    @include flex-center;

                }

                div {
                    span {
                        @include text-bold;
                        color: $dark-black;
                        display: block;
                        padding-left: 12px;
                    }
                }
            }
        }
    }
}

@keyframes tostermodal {
    0% {
        transform: translateY(-100%);
    }

    50% {
        transform: translateY(0%);
    }

    100% {
        transform: translateY(0%);
    }
}