@import '../../../styles/mixins/global.scss';

.askedQuestionsSection {
    padding: 100px 0;

    @include breakpoint("max-xl") {
        padding: 80px 0;
    }

    @include breakpoint("max-sm") {
        padding: 86px 0;
    }

    .pageTitle {
        padding: 0 0 44px 0;

        @include breakpoint("max-sm") {
            padding: 0 0 80px 0;
        }

        h1 {
            @include text-5xl;
            @include text-center;
            line-height: 50px;
            color: $black-2;

            @include breakpoint("max-xl") {
                @include heading-small-size;
            }

            @include breakpoint("max-sm") {
                @include heading-6;
            }
        }
    }

    .mainQuestion:last-child {
        margin: 0;
    }

    .mainQuestion {
        background-color: #fafafa;
        padding: 44px 54px;
        user-select: none;
        margin: 0 0 24px 0;

        @include breakpoint("max-sm") {
            padding: 25px;
        }

        .textHidden {
            max-height: 0px;
            transition: .3s ease-in-out;
            overflow: hidden;
        }

        .textShow {
            max-height: calc(100vh - 0px);
            overflow: hidden;
            transition: .3s ease-in-out;
        }

        .childTextAlignment {
            p {
                @include text-20xl;
                color: $black-1-light;
                margin: 40px 0 0 0;

                @include breakpoint("max-sm") {
                    margin: 28px 0 0 0;
                }
            }
        }

        .questionAlignment {
            @include flex-between;
            @include pointer;

            div {
                h2 {
                    @include heading-3xl;
                    letter-spacing: -0.62px;
                    color: $black-2;
                    line-height: 32px;
                    color: $black-2;

                    @include breakpoint("max-sm") {
                        @include text-3xl;
                        font-family: 'Sofia-Bold';
                        max-width: calc(195px);

                    }
                }
            }

            div+div {
                i {
                    color: $black-2;
                    @include pointer;
                    font-size: 22px;
                }
            }
        }
    }
}