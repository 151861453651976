@import '../../../styles/mixins/global.scss';

.authenticationLifecycle {
    padding: 100px 0;

    @include breakpoint("max-xl") {
        padding: 80px 0;
    }

    @include breakpoint("max-md") {
        padding: 80px 0;
    }

    @include breakpoint("max-sm") {
        padding: 65px 0 120px 0;
    }

    .pageTitle {
        padding: 0 0 map-get($pixel, md) * 10 - 3px 0;

        @include breakpoint("max-sm") {
            padding: 0 0 43px 0;
        }

        h1 {
            @include text-center;
            @include text-5xl;
            margin: 0 0 map-get($pixel, md) * 4 + 4px 0;
            color: $black-2;

            @include breakpoint("max-xl") {
                @include heading-small-size;
                margin: 0 0 30px 0;
            }

            @include breakpoint("max-sm") {
                @include heading-6;
                margin: 0 auto 31px auto;
                max-width: 300px;
            }

            a {
                @include text-gradient;
            }
        }

        p {
            @include text-xl-normal;
            letter-spacing: 0px;
            @include text-center;
            max-width: 920px;
            line-height: 32px;
            margin: 0 auto;
            color: $black-1-light;

            @include breakpoint("max-xl") {
                max-width: 860px;
            }

            @include breakpoint("max-sm") {
                line-height: 30px;
                max-width: 315px;
                letter-spacing: -0.44px;
            }
        }
    }

    .grid {
        @include grid-layout;
        gap: 60px;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) max(287px);

        @include breakpoint("max-md") {
            grid-template-columns: repeat(2, 1fr);
            gap: 40px;
        }

        @include breakpoint("max-sm") {
            grid-template-columns: repeat(1, 1fr);
            gap: 70px;
        }

        .gridItems:nth-child(1) {
            h2 {
                max-width: 210px;
                @include text-center;

                @include breakpoint("max-sm") {
                    max-width: 100%;
                }
            }
        }

        .gridItems:nth-child(2) {
            h2 {
                position: relative;
                left: -10%;

                @include breakpoint("max-sm") {
                    left: 0;
                    @include text-center;
                }
            }
        }

        .gridItems:last-child {
            .iconAlignment {
                @include flex-center;
                justify-content: map-get($layout, center);
            }

            .steperArrow {
                display: none;
            }
        }

        .gridItems {
            position: relative;

            .steperArrow {
                width: calc(100% - 230px);
                height: 1px;
                border-top: 3px dashed #dacec0;
                top: 105px;
                content: "";
                position: absolute;
                right: 0;

                &::before {
                    background-image: url("../../../assets/icons/right-before-arrow.png");
                    background-repeat: no-repeat;
                    background-size: cover;
                    right: -8px;
                    top: -9px;
                    position: absolute;
                    width: 15px;
                    height: 15px;
                    content: "";
                }

                @include breakpoint("max-md") {
                    display: none;
                }
            }

            .iconAlignment {
                padding: 0 0 map-get($pixel, md) * 2 0;

                @include breakpoint("max-md") {
                    @include flex-center;
                    justify-content: center;
                }

                @include breakpoint("max-sm") {
                    padding: 0 0 10px 0;
                }
            }

            h2 {
                @include heading-3;
                color: $black-1-light;

                @include breakpoint("max-md") {
                    @include text-center;
                }

                @include breakpoint("max-sm") {
                    @include text-2xl-bold;
                }
            }
        }
    }
}