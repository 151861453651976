@import '../../../styles/mixins/global.scss';

.marketingAnalyticsBanner {
    background-color: $blue-color-light-400;
    padding: 100px 0;

    @include breakpoint("max-xl") {
        padding: 80px 0;
    }

    @include breakpoint("max-md") {
        padding: 80px 0;
    }

    @include breakpoint("max-sm") {
        padding: 30px 0 40px 0;
    }

    .grid {
        @include grid-layout;
        grid-template-columns: minmax(0, 1fr) 666px;
        gap: 0 62px;
        align-items: center;

        @include breakpoint("max-xl") {
            grid-gap: 0 45px;
            grid-template-columns: minmax(0px, 1fr) 568px;
        }

        @include breakpoint("max-md") {
            grid-template-columns: repeat(1, 1fr);
            gap: 80px;
        }

        @include breakpoint("max-sm") {
            grid-template-columns: repeat(1, 1fr);
            gap: 21px;
        }

        .gridItems:nth-child(1) {
            @include breakpoint("max-sm") {
                order: 2;
            }
        }

        .gridItems {
            h1 {
                @include text-5xl;
                color: $white-color;
                margin: 0 0 map-get($pixel, md) * 2 + 4px 0;

                @include breakpoint("max-xl") {
                    @include heading-small-size;
                }

                @include breakpoint("max-sm") {
                    @include text-2xl-bold;
                    margin: 0 0 20px 0;
                }
            }

            p {
                @include text-xl-normal;
                color: $white-color;
                letter-spacing: 0px;
                line-height: 36px;

                @include breakpoint("max-xl") {
                    max-width: 590px;
                }

                @include breakpoint("max-sm") {
                    line-height: 30px;
                    max-width: 300px;
                    letter-spacing: -0.44px;
                }
            }
        }
    }
}