@import '../../../styles/mixins/global.scss';

.marketingAnalyticsBanner {
    background-image: url("../../../assets/Images/marketingAnalyticsBanner.png");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    
    @media(min-width: 2000px){
        &::after{
            position: absolute;
            bottom: 0px;
            background: #fff;
            content: '';
            height: 520px;
            width: 100%;
            z-index: 0;
            -webkit-mask-image: linear-gradient(to bottom,transparent 7%, black 60%);
            mask-image: linear-gradient(to bottom,transparent 7%, black 60%);
        }
    }

    .heroTextAlignment {
        @include flex-center;
        padding: 120px 0 0 0;
        justify-content: center;

        @include breakpoint("max-sm") {
            padding: 66px 0 0 0;
        }

        .heroText {
            width: calc(716px);

            h1 {
                @include heading-lg-2;
                color: $white-color;
                @include text-center;
                line-height: 59px;
                margin: 0 0 22px 0;

                @include breakpoint("max-xl") {
                    @include heading-small-size;
                    max-width: 450px;
                    margin: 0 auto 22px auto;

                }

                br {
                    @include breakpoint("max-sm") {
                        display: none;
                    }
                }

                @include breakpoint("max-sm") {
                    @include heading-3;
                    margin: 0 auto 20px auto;
                    letter-spacing: -0.71px;
                    max-width: calc(258px);
                }

                a {
                    @include text-gradient;
                }
            }

            .buttonCenterAlignment {
                @include flex-center;
                justify-content: center;
                padding: 24px 0 0 0;

                @include breakpoint("max-sm") {
                    padding: 17px 0 0 0;
                }
            }

            p {
                @include text-20xl;
                color: $white-color;
                @include text-center;
                max-width: calc(462px);
                margin: 0 auto;

                @include breakpoint("max-sm") {
                    @include text-md;
                    line-height: 28px;
                    max-width: calc(277px);
                }
            }
        }
    }
}

.salesOverviewAlignment {
    padding: 90px 0 0 0;

    @include breakpoint("max-sm") {
        padding: 50px 0 0 0;
    }
    position: relative;
    z-index: 2;
}