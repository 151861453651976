@import '../../../styles/mixins/global.scss';

.newsSection {
    padding: 100px 0;

    @include breakpoint("max-xl") {
        padding: 80px 0;
    }

    @include breakpoint("max-sm") {
        padding: 60px 0 90px 0;
    }

    .pageTitle {
        padding: 0 0 map-get($pixel, md) * 4 + 3px 0;

        @include breakpoint("max-sm") {
            padding: 0 0 40px 0;
        }

        h1 {
            @include text-5xl;
            color: $black-1;
            margin: 0;

            @include breakpoint("max-sm") {
                @include heading-6;
            }
        }
    }

    .grid {
        @include grid-layout;
        grid-template-columns: repeat(12, 1fr);
        gap: 21px;

        @include breakpoint("max-md") {
            grid-template-columns: repeat(1, 1fr);
        }

        @include breakpoint("max-sm") {
            grid-template-columns: repeat(1, 1fr);
            gap: 25px;
        }

        .gridItems {
            grid-column: span 4;
            background-color: $bg-light-200;
            @include rounded;
            padding: map-get($pixel, md) * 3;

            @include breakpoint("max-xl") {
                padding: 25px;
            }

            @include breakpoint("max-sm") {
                padding: 0;
                grid-column: auto;
            }

            .articleGrid {
                @include grid-layout;
                grid-template-columns: repeat(1, 1fr);
                align-items: center;
                gap: 14px;

                @include breakpoint("max-md") {
                    grid-template-columns: 250px minmax(0, 1fr);
                    gap: 0 20px;
                }

                @include breakpoint("max-sm") {
                    grid-template-columns: repeat(1, 1fr);
                    gap: 0 20px;
                }

                .articleGridItems:last-child {
                    @include breakpoint("max-sm") {
                        padding: 25px;
                    }
                }

                .articleGridItems {
                    img {
                        width: 100%;
                        height: 167px;
                        object-fit: cover;
                        @include rounded;

                        @include breakpoint("max-sm") {
                            height: 180px;
                        }
                    }

                    .readmore {
                        @include flex-center;
                        @include pointer;

                        span {
                            @include text-xl-medium;
                            color: $black-1;
                            display: block;
                            padding-right: 5px;
                        }

                        i {
                            color: $black-1;
                            position: relative;
                            top: 1px;
                            @include flex-center;
                            font-size: 24px;
                        }
                    }

                    p {
                        @include text-md-regular;
                        margin: 0 0 map-get($pixel, md) * 3 0;
                        color: $black-1;

                        @include breakpoint("max-xl") {
                            font-size: 16px;
                            line-height: 28px;
                        }

                        @include breakpoint("max-sm") {
                            font-size: 18px;
                            margin: 0 0 15px 0;
                            max-width: 279px;
                        }
                    }

                    h2 {
                        @include heading-3xl;
                        margin: 0 0 map-get($pixel, md) 0;
                        color: $blue-color;
                    }
                }
            }
        }
    }
}