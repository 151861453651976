@import '../../../styles/mixins/global.scss';


.universalBlockchainSection {
    padding: 100px 0;

    @include breakpoint("max-xl") {
        padding: 80px 0;
    }

    @include breakpoint("max-sm") {
        padding: 53px 0 80px 0;
    }

    .blockChainrightAlignment {
        padding-right: 8.8%;

        @include breakpoint("max-xl") {
            padding-right: 40px;
        }

        @include breakpoint("max-md") {
            padding: 0 40px;
        }

        @include breakpoint("max-sm") {
            padding: 0 24px;
        }

        .grid {
            @include grid-layout;
            grid-template-columns: minmax(0, 1fr) 652px;
            gap: 0 116px;
            align-items: center;

            @include breakpoint("max-xl") {
                grid-template-columns: repeat(2, 1fr);
            }

            @include breakpoint("max-md") {
                grid-template-columns: repeat(1, 1fr);
                gap: 60px;
            }

            @include breakpoint("max-sm") {
                grid-template-columns: repeat(1, 1fr);
                gap: 37px;
            }

            .gridItems:nth-child(1) {
                @include breakpoint("max-sm") {
                    order: 2;
                }
            }

            .gridItems {
                img {
                    width: 100%;
                    height: 680px;
                    border-radius: 0 16px 16px 0;
                    object-fit: cover;

                    @include breakpoint("max-xl") {
                        height: 550px;
                    }

                    @include breakpoint("max-sm") {
                        height: 337px;
                        border-radius: 0px;
                    }
                }

                h1 {
                    @include text-5xl;
                    max-width: 522px;
                    color: $black-1;
                    line-height: 55px;
                    margin: 0;
                    padding-right: 6px;
                    max-width: 401px;

                    @include breakpoint("max-xl") {
                        max-width: 370px;
                        @include heading-small-size;
                    }

                    @include breakpoint("max-sm") {
                        @include text-5xl;
                        display: none;
                    }
                }

                h2 {
                    display: none;

                    @include breakpoint("max-sm") {
                        display: block;
                        @include text-5xl;
                        line-height: 55px;
                        margin: 0 0 33px 0;

                        a {
                            @include text-gradient;
                        }
                    }
                }

                .responsiveText {
                    @include breakpoint("max-xl") {
                        max-width: 601px;
                    }

                    @include breakpoint("max-sm") {
                        max-width: 295px;
                    }
                }

                .responsiveTextChild {
                    @include breakpoint("max-xl") {
                        max-width: 529px;
                    }
                }

                p {
                    @include text-xl-normal;
                    color: $black-2;
                    max-width: 660px;
                    line-height: 36px;
                    margin: 0 0 map-get($pixel, md) * 2 0;

                    @include breakpoint("max-xl") {
                        line-height: 30px !important;
                    }

                    @include breakpoint("max-sm") {
                        @include text-xl-normal;
                        line-height: 30px !important;
                        letter-spacing: -0.44px;
                        max-width: 303px;
                        margin: 0 0 20px 0;
                    }
                }

                p:last-child {
                    max-width: 594px;

                    @include breakpoint("max-xl") {
                        max-width: 529px;
                    }

                    @include breakpoint("max-sm") {
                        max-width: 341px;
                    }
                }

                span {
                    @include text-5xl;
                    line-height: 55px;
                    display: block;
                    padding: 0 0 map-get($pixel, md) * 3 - 2px 0;
                    @include text-gradient;

                    @include breakpoint("max-xl") {
                        @include heading-small-size;
                    }

                    @include breakpoint("max-sm") {
                        display: none;
                    }
                }
            }
        }
    }
}

.technologySection {
    padding: 0 0 120px 0;

    @include breakpoint("max-xl") {
        padding: 0 0 90px 0;
    }

    @include breakpoint("max-sm") {
        padding: 0 0 80px 0;
    }

    .grid {
        @include grid-layout;
        grid-template-columns: repeat(12, 1fr);
        gap: 20px;

        @include breakpoint("max-md") {
            grid-template-columns: repeat(1, 1fr);
            gap: 40px;
        }

        .gridItems {
            grid-column: span 6;
            padding: 40px 30px;
            @include rounded;
            background-color: $bg-light-200;

            @include breakpoint("max-sm") {
                padding: 40px 25px;
                grid-column: auto;
            }

            .subGrid {
                @include grid-layout;
                grid-template-columns: 188px minmax(0, 1fr);
                gap: map-get($pixel, md) * 2 + 2px;
                align-items: center;

                @include breakpoint("max-xl") {
                    grid-template-columns: 150px minmax(0, 1fr);
                    align-items: flex-start
                }

                @include breakpoint("max-sm") {
                    grid-template-columns: repeat(1, 1fr);
                    align-items: flex-start;
                    gap: 23px;
                }

                .subGridItems:nth-child(1) {
                    @include breakpoint("max-sm") {
                        @include flex-center;
                        justify-content: center;
                    }
                }

                .subGridItems {
                    h2 {
                        @include text-2xl-bold;
                        color: $blue-color;
                        letter-spacing: -0.53px;
                        font-size: 23px;
                        margin: 0 0 map-get($pixel, sm) * 3 + 3px 0;

                        @include breakpoint("max-sm") {
                            font-size: 28px;
                            @include text-center;
                            font-family: 'Sofia-Medium';
                            font-weight: 500;
                            letter-spacing: -0.62px;
                            line-height: 32px;
                            margin: 0 0 23px 0;
                        }
                    }

                    p {
                        @include text-md-regular;
                        color: $black-1;
                        line-height: 31px;
                        letter-spacing: -0.4px;
                        margin: 0;
                        opacity: 0.73;
                        padding-right: 1px;

                        @include breakpoint("max-xl") {
                            font-size: 16px;
                            line-height: 28px;
                        }

                        @include breakpoint("max-sm") {
                            @include text;
                            letter-spacing: -0.49px;
                            max-width: 271px;
                            margin: 0 auto;
                            padding: 0;
                            @include text-center;
                        }
                    }

                    img {
                        width: 188px;
                        height: 188px;

                        @include breakpoint("max-xl") {
                            width: 150px;
                            height: 150px;
                        }

                        @include breakpoint("max-sm") {
                            width: 210px;
                            height: 210px;
                        }
                    }
                }
            }
        }
    }
}