@import '../../styles/mixins/global.scss';

.FourOFour {
  height: 75vh;
  max-height: 1600px;
  min-height: 600px;
  font-family: 'Sofia-regular';
  background: $blue-color-100;
  position: relative;

  @media(min-width:700px) {
    background: url("../../assets/Images/404.webp"), $blue-color-100 ;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  @media(min-width: 1200px) {
    background: url("../../assets/Images/404@2x.webp"), $blue-color-100 ;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  @media(min-width: 1600px) {
    background: url("../../assets/Images/404@3x.webp"), $blue-color-100 ;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  @media(min-width: 2900px) {
    background: url("../../assets/Images/404@3x.webp"), $blue-color-100 ;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    background-origin: content-box;
  }

  .Header {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: fit-content;

    h1 {
      @include text-gradient;
      background-image: linear-gradient(to right, #fff78b -4%, #feac5d 97%);
      font-size: 150px;

      @media(min-width: 800px) {
        font-size: 200px;
      }

      margin-bottom: 0px;
    }

    p {
      margin-top: -10px;
      font-size: 24px;
      color: #fefefe;
      line-height: 1.33;
    }

    button {
      margin-top: 40px;
      margin-left: auto;
      margin-right: auto;
      min-width: 188px;
      padding: 4px 10px;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0.34px;
      background-color: $white-color;
      color: $blue-color;
      @include pointer;
      border: none;
      border-radius: 999px;
      height: 44px;
      @include ease-in-out;

      &:hover {
        background-color: $orange-light-color;
        color: $white-color;
        @include ease-in-out;
      }

      @include breakpoint("max-sm") {
        min-width: 187px;
        height: 44px;
        @include text-md-bold;

      }
    }
  }
}