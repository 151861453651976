.header-dropdown {
    position: absolute;
    background-color: $white-color;
    top: 28px;
    border-radius: 6px;
    box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.12);
    z-index: 7;


    .header-dropdown-alignment {
        padding: 30px;

        a {
            @include text-sm;
            color: #191919;
            font-family: 'Sofia-Medium';
            white-space: nowrap;
            display: block;
            padding: 0 0 25px 0;
            @include ease-in-out;

            &:hover {
                transform: translate(0, -3px);
            }
        }

        a:last-child {
            padding: 0;
        }
    }
}

.header-dropdown-hidden {
    max-height: 0px;
    @include ease-in-out;
    overflow: hidden;
}

.header-dropdown-show {
    max-height: calc(100vh - 0px);
    @include ease-in-out;
    overflow: hidden;
}

.header-dropdown-without-back {
    top: 56px !important;
}