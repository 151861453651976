@import '../../styles/mixins/global.scss';

.ComingSoon {
  height: 50vh;
  max-height: 1600px;
  min-height: 600px;
  font-family: 'Sofia-regular';
  background: $blue-color-100;
  position: relative;

  background-image: linear-gradient(114deg, #041260, #3898e9 100%);

  .Header {
    font-family: 'Sofia-regular';
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: fit-content;
    color: #fefefe;

    .Highlight {
      background-image: linear-gradient(to right, #fff78b -38%, #ffb873 97%);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    h1 {
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: 0.3px;
    }

    h3 {
      font-size: 40px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.17;
      letter-spacing: -1.29px;
      margin-bottom: 20px;
    }

    p {
      max-width: 652px;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
    }

    button {
      margin-top: 40px;
      margin-left: auto;
      margin-right: auto;
      min-width: 155px;
      padding: 2px 8px;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.34px;
      background-color: $white-color;
      color: $blue-color;
      @include pointer;
      border: none;
      border-radius: 999px;
      height: 38px;
      @include ease-in-out;

      &:hover {
        background-color: $orange-light-color;
        color: $white-color;
        @include ease-in-out;
      }
    }
  }
}