html,
body {
  padding: 0;
  scroll-behavior: smooth;
  margin: 0;
  overflow-x: hidden;
  font-family: 'Sofia Pro', sans-serif;
  overscroll-behavior-y: none;
  user-select: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  line-height: normal !important;
  vertical-align: center !important;
}


*,
*::after,
*::before {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*:focus {
  outline: 0;
  box-shadow: none;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
}

ul,
li {
  padding: 0;
  margin: 0;
  display: block;
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  max-width: 100%;
}

.scroll-container {
  position: fixed;
  left: 0;
  transition: ease-in-out;
  width: 100%;
  will-change: transform;
}

.bodyHidden {
  overflow: hidden;
}

.overFlow {
  overflow: hidden;
}

.fa-angle-down {
  transition: 0.3s ease-in-out;
}

.rotate-180 {
  transform: rotate(180deg);
}