@import '../../../styles/mixins/global.scss';

.whiteLabelServiceAlign {
    padding: 100px 0;

    @include breakpoint("max-xl") {
        padding: 80px 0;
    }

    @include breakpoint("max-sm") {
        padding: 82px 0;
    }

    .grid {
        @include grid-layout;
        grid-template-columns: repeat(12, 1fr);
        gap: map-get($pixel, md) * 5 + 6px;

        @include breakpoint("max-md") {
            grid-template-columns: repeat(2, 1fr);
            gap: 60px;
        }

        @include breakpoint("max-sm") {
            grid-template-columns: repeat(1, 1fr);
            gap: 80px;
        }

        .gridItems {
            grid-column: span 4;

            @include breakpoint("max-md") {
                grid-column: auto;
            }

            .iconCenterAlignment {
                @include flex-center;
                height: 80px;
                justify-content: map-get($layout, center);
                padding: 0 0 map-get($pixel, sm) * 4 - 1px 0;
            }

            h2 {
                @include heading-3xl;
                color: $black-1-light;
                @include text-center;
                margin: 0 0 map-get($pixel, sm) * 3 - 1px 0;

                @include breakpoint("max-sm") {
                    @include text-2xl-bold;
                    margin: 0 0 18px 0;
                }
            }

            p {
                @include text-xl-normal;
                letter-spacing: 0px;
                line-height: 32px;
                color: $black-1-light;
                @include text-center;

                @include breakpoint("max-xl") {
                    line-height: 30px !important;
                }

                @include breakpoint("max-sm") {
                    line-height: 30px;
                    max-width: 323px;
                    padding: 0 6px;
                    margin: 0 auto;
                    letter-spacing: -0.44px;
                }
            }
        }
    }
}