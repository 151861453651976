@import '../../../styles/mixins/global.scss';

.adoptBlockchainSection {
    background-color: $bg-white-light-color;
    padding: 100px 0;

    @include breakpoint("max-xl") {
        padding: 80px 0;
    }

    @include breakpoint("max-sm") {
        padding: 67px 0;
    }

    .pageTitle {
        padding: 0 0 67px 0;

        @include breakpoint("max-sm") {
            padding: 0 0 40px 0;
        }

        h1 {
            max-width: 525px;
            margin: 0;
            color: $black-2;
            @include text-5xl;

            @include breakpoint("max-xl") {
                max-width: 475px;
                @include heading-small-size;
            }

            @include breakpoint("max-xl") {
                max-width: 530px;
            }

            @include breakpoint("max-sm") {
                @include heading-6;
                letter-spacing: -0.8px;
                padding-right: 45px;
                max-width: 285px;
            }

            a {
                @include text-gradient;
            }
        }
    }

    .grid {
        @include grid-layout;
        grid-template-columns: repeat(12, 1fr);
        gap: map-get($pixel, md) * 6;

        @include breakpoint("max-md") {
            grid-template-columns: repeat(1, 1fr);
            gap: 40px;
        }

        @include breakpoint("max-sm") {
            grid-template-columns: repeat(1, 1fr);
            gap: 24px;
        }

        .gridItems {
            grid-column: span 6;
            padding: 76px 82px;
            @include rounded;
            background-color: $white-color;

            @include breakpoint("max-xl") {
                padding: 81px;
            }

            @include breakpoint("max-md") {
                grid-column: auto;
            }

            @include breakpoint("max-sm") {
                padding: 30px 28px;
            }

            h3 {
                @include heading-3xl;
                color: $black-2;
                margin: 0 0 10px 0;

                @include breakpoint("max-sm") {
                    @include text-2xl-bold;
                    margin: 0 0 8px 0;
                }
            }

            p {
                color: $black-2;
                @include text-xl-normal;
                line-height: 32px;
                letter-spacing: 0px;

                @include breakpoint("max-xl") {
                    line-height: 30px;
                }

                @include breakpoint("max-sm") {
                    @include text-xl-normal;
                    letter-spacing: -0.44px;
                    line-height: 29px !important;
                    max-width: 257px;
                }
            }

            .iconAlignment {
                padding: 0 0 18px 0;

                div {
                    height: 84px;
                }

                div:first-child {
                    max-width: 126px;
                }

                @include breakpoint("max-sm") {
                    padding: 0 0 25px 0;
                }
            }

        }
    }
}