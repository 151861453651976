@import '../../../styles/mixins/global.scss';

.marketingAnalyticsStep {
    padding: 200px 0 120px 0;

    @include breakpoint("max-xl") {
        padding: 100px 0;
    }

    @include breakpoint("max-md") {
        padding: 80px 0;
        margin-top: 18rem;
    }

    @include breakpoint("max-sm") {
        padding: 90px 0;
        margin-top: 0px;
    }

    .grid {
        @include grid-layout;
        grid-template-columns: repeat(3, 1fr);
        gap: 0 107px;

        @include breakpoint("max-xl") {
            gap: 0 87px;
        }

        @include breakpoint("max-md") {
            grid-template-columns: repeat(2, 1fr);
            gap: 40px;
        }

        @include breakpoint("max-sm") {
            grid-template-columns: repeat(1, 1fr);
            gap: 76px;
        }

        .gridItems {
            .iconCenterAlignment {
                @include flex-center;
                justify-content: map-get($layout, center);
                height: 90px;
                padding: 0 0 28px 0;

                @include breakpoint("max-sm") {
                    height: 80px;
                    padding: 0 0 16px 0;
                }

                div {
                    @include breakpoint("max-sm") {
                        height: 100%;
                    }
                }
            }

            p {
                @include text-20xl;
                color: $black-2;
                margin: 0 auto;
                @include text-center;
                max-width: calc(350px);

                @include breakpoint("max-sm") {
                    @include text-md-regular;
                    max-width: calc(271px);
                }
            }

            h2 {
                @include heading-3xl;
                color: $black-2;
                margin: 0 0 15px 0;
                @include text-center;

                @include breakpoint("max-sm") {
                    @include text-2xl-bold;
                    margin: 0 0 8px 0;
                }
            }
        }
    }
}