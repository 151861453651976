@mixin pointer {
    cursor: map-get($layout, pointer );
}

@mixin text-center {
    text-align: map-get($layout, center );
}

@mixin relative {
    position: map-get($layout, relative );
}

@mixin grid {
    display: map-get($layout, grid );
}

@mixin text-capitalize {
    text-transform: capitalize;
}

@mixin normal-radius {
    border-radius: 8px;
}

@mixin grid-layout {
    display: map-get($layout, grid);
}

@mixin text-gradient {
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(to right, #fff78b -38%, #ff8e20 97%);
    
}

@mixin rounded {
    border-radius: map-get($pixel, md ) ;
}