@import '../../styles/mixins/global.scss';


.universalCurrencyBanner {
    background-image: url("../../assets/Images/universalCurrencyBanner.png");
    background-repeat: no-repeat;
    height: 850px;
    background-size: cover;
    width: 100%;

    @include breakpoint("max-xl") {
        background-position: center;
    }

    @include breakpoint("max-md") {
        height: 450px;
    }

    @include breakpoint("max-sm") {
        background-image: url("../../assets/Images/universalCurrencyBanner-mobile.png");
        height: 705px;
    }

    .universalCurrencyAlignment {
        padding: 326px - 98px 0 0 0;

        @include breakpoint("max-md") {
            padding: 60px 0 0 0;
        }

        @include breakpoint("max-sm") {
            padding: 120px 0 0 0;
        }

        div {
            @include breakpoint("max-sm") {
                @include flex-center;
                justify-content: center;
            }

            button {

                @include breakpoint("max-sm") {
                    min-width: 187px;
                }
            }
        }

        h1 {
            @include heading-lg-2;
            margin: 0 0 14px 0;
            color: $white-color;

            @include breakpoint("max-xl") {
                @include heading-small-size;
            }

            @include breakpoint("max-sm") {
                @include heading-6;
                @include text-center;
                margin: 0 auto 24px auto;
                max-width: 200px;
            }

            a {
                @include text-gradient;
            }
        }

        p {
            @include text-3xl-normal;
            color: $white-color;
            margin: 0 0 24px 0;
            line-height: 30px;
            max-width: calc(497px);

            @include breakpoint("max-xl") {
                @include text-md-regular;
                max-width: 400px;
            }

            @include breakpoint("max-sm") {
                @include text-md-regular;
                margin: 0 auto;
                max-width: calc(247px);
                padding: 0 0 26px 0;
                @include text-center;
            }
        }
    }
}