@import '../../styles/mixins/global.scss';

.pageFooterAlign {
    margin-top: -2px;
}

.universalBlockchainSectionAlign {
    background-repeat: no-repeat;
    height: 100vh;
    background-size: cover;
    position: relative;
    width: 100%;
    position: relative;

    video {
        width: 100%;
        height: 100vh;
        object-fit: cover;

        @include breakpoint("max-md") {
            display: none;
        }
    }

    .mobileDeviceGifLoad {
        display: none;

        @include breakpoint("max-md") {
            display: block;
        }

        img {
            width: 100%;
            height: 100vh;
            object-fit: cover;
        }
    }

    .allContentAlignment {
        position: absolute;
        top: 0;
        width: 100%;
    }

    .bannerTextAlignment {
        padding-top: 263px - 94px;

        @include breakpoint("max-sm") {
            padding-top: 161px - 94px;
        }

        .textboxCenter {
            @include flex-center;
            justify-content: map-get($layout, center);

            .textbox {
                max-width: 566px;
                position: relative;

                p {
                    @include text-2xl-regular;
                    color: $white-color;
                    margin: 0 auto 38px auto;
                    @include text-center;
                    max-width: 550px;

                    @include breakpoint("max-xl") {
                        @include text-md-regular;
                        max-width: 400px;
                    }

                    @include breakpoint("max-sm") {
                        @include text-md;
                        max-width: 277px;
                        margin: 0 auto 20px auto;
                    }
                }

                div {
                    @include flex-center;
                    justify-content: map-get($layout, center);

                    button {
                        min-width: 188px;
                        padding: 4px 10px;
                        @include text-md-bold;
                        letter-spacing: 0.34px;
                        background-color: $white-color;
                        color: $blue-color;
                        @include pointer;
                        border: none;
                        border-radius: 999px;
                        height: 44px;
                        @include ease-in-out;

                        &:hover {
                            background-color: $orange-light-color;
                            color: $white-color;
                            @include ease-in-out;
                        }

                        @include breakpoint("max-sm") {
                            min-width: 187px;
                            height: 44px;
                            @include text-md-bold;

                        }
                    }
                }

                h1 {
                    @include heading-lg;
                    font-size: map-get($pixel, md) * 5 + 8px;
                    color: $white-color;
                    letter-spacing: -1.29px;
                    margin: 0 0 24px 0;
                    line-height: 64px;
                    @include text-center;

                    @include breakpoint("max-xl") {
                        @include heading-small-size;
                        max-width: 400px;
                        margin: 0 auto 24px auto;
                    }

                    @include breakpoint("max-sm") {
                        @include heading-6;
                        padding: 0 20px;
                    }

                    a {
                        @include text-gradient;
                    }
                }
            }
        }

    }
}