@import '../../../styles/mixins/global.scss';

.digitalWalletsSection {
    padding: 100px 0;

    @include breakpoint("max-xl") {
        padding: 80px 0 0 0;
    }

    @include breakpoint("max-md") {
        padding: 80px 0;
    }

    @include breakpoint("max-sm") {
        padding: 50px 0;
    }

    .grid {
        @include grid-layout;
        grid-template-columns: 539px minmax(0, 1fr);
        align-items: center;
        gap: 0 233px;

        @include breakpoint("max-xl") {
            gap: 0 175px;
        }

        @include breakpoint("max-md") {
            grid-template-columns: repeat(1, 1fr);
            gap: 80px;
        }

        @include breakpoint("max-sm") {
            grid-template-columns: repeat(1, 1fr);
            gap: 25px;
        }

        .gridItems {
            h1 {
                @include text-5xl;
                margin: 0 0 map-get($pixel, md) * 3 + 2px 0;
                color: $black-2;

                @include breakpoint("max-xl") {
                    @include heading-small-size;
                }

                @include breakpoint("max-sm") {
                    @include text-2xl-bold;
                    margin: 0 0 20px 0;
                    padding-right: 25px;
                    max-width: 280px;
                }
            }

            p {
                max-width: 500px;
                @include text-xl-normal;
                color: $black-2;
                letter-spacing: 0px;
                line-height: 36px;

                @include breakpoint("max-xl") {
                    line-height: 30px !important;
                    max-width: 450px;
                }

                @include breakpoint("max-sm") {
                    line-height: 30px;
                    max-width: 312px;
                    letter-spacing: -0.44px;
                }
            }
        }
    }
}

.moreThanDataAlignment {
    padding: 0 0 120px 0;

    .pageTitle {
        padding: 0 0 75px 0;

        @include breakpoint("max-sm") {
            padding: 0 0 38px 0;
        }

        h1 {
            margin: 0;
            @include text-5xl;
            color: $black-2;
            @include text-center;

            @include breakpoint("max-xl") {
                @include heading-small-size;
            }

            @include breakpoint("max-sm") {
                @include heading-6;
                max-width: calc(170px);
                margin: 0 auto;
            }

            span {
                @include text-gradient;
            }
        }
    }

    @include breakpoint("max-sm") {
        padding: 0 0 80px 0;
    }

    .grid {
        @include grid-layout;
        grid-template-columns: repeat(12, 1fr);
        gap: 44px;

        @include breakpoint("max-md") {
            grid-template-columns: repeat(2, 1fr);
        }

        @include breakpoint("max-sm") {
            grid-template-columns: repeat(1, 1fr);
        }

        .gridItems {
            grid-column: span 4;

            @include breakpoint("max-md") {
                grid-column: auto;
            }

            .cardImage {
                @include flex-center;
                justify-content: center;
                max-width: 337px;
                margin: 0 auto;
                height: 190px;
                padding: 0 0 map-get($pixel, md) * 3 0;

                @include breakpoint("max-sm") {
                    padding: 0 0 1rem 0;
                }
            }

            .cardDetails {
                h3 {
                    @include heading-3xl;
                    color: $black-1;
                    padding: 0 0 map-get($pixel, sm) * 3 + 3px 0;
                    @include text-center;
                    max-width: 368px;
                    margin: 0 auto;
                    letter-spacing: 0px;

                    @include breakpoint("max-sm") {
                        max-width: 322px;
                        @include text-2xl-bold;
                        margin: 0 auto;
                        padding: 0 0 8px 0;
                    }
                }

                p {
                    @include text-xl-normal;
                    @include text-center;
                    line-height: 34px;
                    letter-spacing: 0px;
                    color: $black-1;
                    max-width: 379px;
                    margin: 0 auto;

                    @include breakpoint("max-xl") {
                        line-height: 30px !important;
                        max-width: 358px;
                    }

                    @include breakpoint("max-sm") {
                        line-height: 30px;
                        max-width: 327px;
                        @include text-center;
                        letter-spacing: -0.44px;
                    }
                }
            }
        }
    }
}