@import '../../../styles/mixins/global.scss';

.firstOfKitBanner {
    background: linear-gradient(90deg, #05115F 0%, #0076D9 100%);
    padding: 100px 0;
    width: 100%;

    .leftAlignFirstOfKit {
        padding-left: max(80px, calc((100vw - 1332px) / 2));

        @include breakpoint("max-sm") {
            padding: 0 20px;
        }
    }

    @include breakpoint("max-md") {}

    @include breakpoint("max-sm") {
        padding: 65px 0;
    }

    .firstOfKitGrid {
        display: grid;
        grid-template-columns: 495px minmax(0, 1fr);
        gap: 0 0;
        align-items: center;

        @include breakpoint("max-md") {
            grid-template-columns: repeat(1, 1fr);
            gap: 60px;
        }

        .firstOfKitGridItems:nth-child(2) {
            div:first-child {
                width: 100%;
            }
        }
    }

    .textStyle {
        h1 {
            @include text-5xl;
            color: $white-color;
            line-height: 50px;
            max-width: calc(435px);
            margin: 0 0 map-get($pixel, md) * 2 0;

            @include breakpoint("max-xl") {
                @include heading-small-size;
            }

            @include breakpoint("max-sm") {
                @include heading-6;
                @include text-center;
                max-width: 249px;
                margin: 0 auto 34px auto;
            }

            a {
                @include text-gradient;
            }
        }

        p {
            @include text-20xl;
            color: $white-color;
            max-width: calc(426px);

            @include breakpoint("max-sm") {
                line-height: 30px;
                max-width: calc(297px);
                @include text-center;
                margin: 0 auto;

            }
        }
    }
}