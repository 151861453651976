@import '../../../styles/mixins/global.scss';

.globalNetworkBanner {
    background-image: url("../../../assets/Images/global-network.webp");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    padding: 165px 0;

    @include breakpoint("max-sm") {
        background-image: url("../../../assets/Images/global-network-mobile.webp");
        height: 697px;
        padding: 125px 0 0 0;

    }

    .contentRightAlign {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 60px;

        @include breakpoint("max-sm") {
            grid-template-columns: repeat(1, 1fr);
            gap: 0;
        }

        div {
            @include breakpoint("max-xl") {}

            @include breakpoint("max-sm") {
                display: flex;
                justify-content: center;
                flex-direction: column;
            }

            button {
                min-width: 188px;
                height: 44px;
                background-color: $blue-color-light-400;
                border-radius: 9999px;
                border: none;
                @include text-md-bold;
                letter-spacing: .034px;
                color: $white-color;
                @include text-center;
                @include pointer;
                @include ease-in-out;

                &:hover {
                    transition: .3s ease-in-out;
                    transform: translate(0, -3px);
                    background-color: $orange-light-color;
                    color: $white-color;
                }

                @include breakpoint("max-sm") {
                    height: 46px;
                    min-width: 198px;
                    @include text-md-bold;
                    letter-spacing: -0.4px;

                }
            }

            a {
                @include breakpoint("max-sm") {
                    @include flex-center;
                    justify-content: center;
                }
            }

            h1 {
                margin: 0 0 50px 0;
                @include text-5xl;
                line-height: 56px;
                color: $white-color;

                @include breakpoint("max-xl") {
                    @include heading-small-size;
                    margin: 0 0 40px 0;
                }

                @include breakpoint("max-sm") {
                    @include heading-6;
                    margin: 0 auto 24px auto;
                    max-width: 250px;
                    @include text-center;
                }

                br {
                    @include breakpoint("max-sm") {
                        display: none;
                    }
                }

                a {
                    @include text-gradient;
                    display: revert;
                }
            }
        }
    }
}