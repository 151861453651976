@import '../../../styles/mixins/global.scss';

.reachYourGoalsBanner {
    background-color: $bg-white-light-color;
    padding: 100px 0;

    @include breakpoint("max-xl") {
        padding: 80px 0;
    }

    @include breakpoint("max-sm") {
        padding: 70px 0 60px 0;
    }

    .grid {
        @include grid-layout;
        grid-template-columns: 452px minmax(0, 1fr);
        align-items: center;
        gap: 0 150px;

        @include breakpoint("max-xl") {
            grid-template-columns: 432px minmax(0, 1fr);
        }

        @include breakpoint("max-md") {
            grid-template-columns: repeat(1, 1fr);
            gap: 40px 0;
        }

        @include breakpoint("max-sm") {
            grid-template-columns: repeat(1, 1fr);
            gap: 35px 0;
        }

        .gridItems {
            h1 {
                @include text-5xl;
                line-height: 50px;
                margin: 0 0 16px 0;
                max-width: 430px;
                color: $black-2;

                @include breakpoint("max-xl") {
                    @include heading-small-size;
                    max-width: 400px;
                }

                @include breakpoint("max-sm") {
                    @include heading-3;
                    max-width: 339px;
                    margin: 0 auto 16px auto;
                    letter-spacing: -0.71px;
                    @include text-center;
                    padding: 0 50px;
                }

                a {
                    @include text-gradient;
                }
            }

            p {
                @include text-20xl;
                color: $black-2;

                @include breakpoint("max-sm") {
                    @include text-md-regular;
                    margin: 0 auto;
                    max-width: calc(327px);
                    padding: 0 38px;
                    @include text-center;
                }
            }
        }
    }
}