@import '../../styles/mixins/global.scss';

.whiteLabelBanner {
    background-image: url("../../assets/Images/white-label.webp");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100vh;

    .whitelabelConetentCenter {
        height: calc(100vh - 100px);
        @include flex-center;

        .pageTitle {
            h1 {
                @include heading-lg-2;
                max-width: calc(477px);
                padding: 0 0 27px 0;
                margin: 0 auto;
                @include text-center;
                color: $white-color;

                @include breakpoint("max-xl") {
                    @include heading-small-size;
                    max-width: 316px;
                }

                @include breakpoint("max-sm") {
                    @include heading-6;
                    max-width: calc(225px);
                    padding: 0 0 20px 0;
                }

                a {
                    @include text-gradient;
                }
            }

            p {
                @include text-xl-normal;
                line-height: 32px;
                color: $white-color;
                @include text-center;
                letter-spacing: 0px;
                max-width: calc(711px);
                margin: 0 auto;

                @include breakpoint("max-xl") {
                    max-width: calc(683px);
                }

                @include breakpoint("max-sm") {
                    @include text-md-regular;
                    max-width: calc(276px);
                }
            }

            .buttonCenterAlignment {
                @include flex-center;
                justify-content: center;
                padding: map-get($pixel, md) * 4 - 2px 0 0 0;

                @include breakpoint("max-sm") {
                    padding: 34px 0 0 0;
                }
            }
        }
    }
}