@import '../../styles/mixins/global.scss';

.aboutHeroSection {
    margin-top: 65px;
    background-color: $bg-white-light-color;
    padding: 120px 0 200px 0;

    @include breakpoint("max-xl") {
        padding: 120px 0 160px;
    }

    @include breakpoint("max-md") {
        min-height: 900px;
    }

    @include breakpoint("max-sm") {
        padding: 32px 0 46px 0;
        min-height: 600px;
    }

    .grid {
        @include grid-layout;
        grid-template-columns: minmax(0, 1fr) 607px;
        grid-gap: 0 61px;
        align-items: center;
        padding: 0 40px;

        @include breakpoint("max-xl") {
            gap: 0 12px;
            grid-template-columns: minmax(0, 1fr) 587px;
        }

        @include breakpoint("max-md") {
            grid-template-columns: repeat(1, 1fr);
            gap: 40px 0;
        }

        @include breakpoint("max-sm") {
            gap: 40px;
            padding: 0;
        }

        .gridItems:nth-child(1) {
            margin-top: 122px;

            @include breakpoint("max-md") {
                margin: 0;
            }
        }

        .gridItems {
            position: relative;
            width: 100%;
            height: 100%;

            .mobileViewImageShow {
                img {
                    display: none;

                    @include breakpoint("max-md") {
                        display: block;
                    }
                }
            }

            .activityBox {
                position: absolute;
                right: -110px;
                bottom: -176px;

                img {
                    max-width: 230px;
                }

                @include breakpoint("max-xl") {
                    right: -70px;
                }

                @include breakpoint("max-sm") {
                    display: none;
                }
            }

            .audienceReachedJson {
                position: absolute;
                z-index: 1;
                top: 0;
                max-width: 400px;
            }

            .aboutMainImage {
                position: absolute;
                z-index: 0;
                top: 14%;
                left: 53%;
                width: 65%;
                transform: translateX(-50%);
            }

            .audienceReached {
                position: absolute;
                border-radius: 8px;
                left: -155px;
                top: -53px;

                img {
                    max-width: 260px;
                }

                @include breakpoint("max-md") {
                    left: -25px;
                }

                @include breakpoint("max-sm") {
                    display: none;
                }
            }

            h1 {
                @include heading-lg-2;
                color: $black-1;
                line-height: 61px;
                margin: 0 0 map-get($pixel, md) * 3 + 3px 0;

                @include breakpoint("max-xl") {
                    @include heading-small-size;
                }

                @include breakpoint("max-sm") {
                    @include heading-6;
                    line-height: 62px;
                    margin: 0 0 17px 0;
                    @include text-center;
                }

                br {
                    @include breakpoint("max-sm") {
                        display: none;
                    }
                }
            }

            p {
                @include text-xl-normal;
                color: $black-1;
                max-width: 560px;
                line-height: 30px;
                margin: 0 0 map-get($pixel, md) * 3 0;

                @include breakpoint("max-xl") {
                    max-width: 100%;
                }

                @include breakpoint("max-sm") {
                    @include text-center;
                    letter-spacing: -0.49px;
                    margin: 0 auto 40px auto;
                    max-width: 246px;
                    line-height: 26px;
                }
            }

            .mobileViewButtonCenter {
                @include breakpoint("max-sm") {
                    @include flex-center;
                    justify-content: center;
                }
            }

            button {
                background-color: $blue-color-light-400;
                min-width: 170px;
                height: 44px;
                @include text-md-bold;
                letter-spacing: 0.38px;
                @include pointer;
                border-radius: 999px;
                border: none;
                color: $white-color;
                transition: .3s ease-in-out;
                padding: 4px 12px;

                &:hover {
                    transition: .3s ease-in-out;
                    transform: translate(0, -3px);
                    background-color: $orange-light-color;
                    color: $white-color;
                }

                @include breakpoint("max-sm") {
                    min-width: 187px;
                    height: 44px;
                    @include text-md-bold;
                    line-height: 2.11;
                    padding: 1px 10px;
                }
            }
        }
    }
}

.aboutBannerMobileViewShow {
    display: none;

    @include breakpoint("max-sm") {
        display: block;
    }
}