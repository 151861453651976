@import '../../../styles/mixins/global.scss';

.heroSection {
    position: map-get($layout, relative);
    top: 80px;

    @include breakpoint("max-xl") {
        top: 70px;
    }

    @media(min-width: 1300px){
        background: rgb(1,130,224);
        background: linear-gradient(180deg, rgba(1,130,224,1) 0%, rgba(3,121,221,1) 54%, rgba(1,109,218,1) 100%);
    }


    @include breakpoint("max-sm") {
        margin: 70px 0 0 0;
        top: 0;
    }

    .mobileViewGifPreload {
        display: block;

        @include breakpoint("max-sm"){
            background: #1d76d9;
        }

        @media(min-width: 1500px) {
            display: none;
        }

        min-height: 650px;
        
        @media(max-width: 780px){
            height: 110vw;
        }
        @media(min-width: 780px){
            height: fit-content;
        }
    }

    .mobileHeroAnimation{
        display: none;
        @include breakpoint("max-sm"){
            display: block;
        }
    }

    .tabletHeroAnimation{
        display: none;
        height: fit-content;
        
        @media(min-width: 765px){
            display: block;
            width: 120%;
            margin-left: -20%;
        }
        @media(max-width: 1092px){
            width: 155vw;
            margin-left: -27.5vw;
            overflow-y: hidden;
        }
        @media(min-width: 782px){
            width: 130vw;
            margin-left: -15vw;
            overflow-y: hidden;
        }
        @media(min-width: 1200px){
            width: 100%;
            margin-left: 0px;
        }
        @media(min-width: 1500px){
            display: none;
        }
    }

    .desktopHeroAnimation{
        display: none;
        max-width: 2200px;
        margin: 0 auto;
        @media(min-width: 2200px){
            display: block;
        }
    }

    video {
        width: 100%;
        height: 1300px;
        object-fit: cover;
        margin-top: -4px;
        display: none;

        @include breakpoint("max-xl") {
            height: 1150px;
        }

        @include breakpoint("max-md") {
            height: 938px;
        }
        @media(min-width: 1500px){
            display: block;
        }
        @media(min-width: 2200px){
            display: none;
        }
    }

    .heroSectionText {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 21%;

        @media(min-width: 768px){
            top: 16%;
        }

        @media(min-width: 899px){
            top: 20%;
        }

        @media(min-width: 1039px){
            top: 20%;
        }

        @include breakpoint("max-sm") {
            width: 100%;
            top: 10%;
        }

        @media(min-width: 2200px){
            top: 18%;   
        }

        .heroText {
            width: 723px;

            @include breakpoint("max-sm") {
                max-width: 297px;
                margin: 0 auto;
            }

            h1 {
                font-family: 'Sofia-Medium' !important;
                color: $white-color;
                @include heading-1;
                letter-spacing: -1.29px;
                @include text-center;

                @include breakpoint("max-xl") {
                    font-size: 35px;
                    line-height: 42px;
                }

                @include breakpoint("max-md") {
                    @include heading-6;
                }

                @include breakpoint("max-sm") {
                    // @include heading-6;
                    font-size: 36px !important;
                    font-weight: 500 !important;
                    font-stretch: normal !important;
                    font-style: normal !important;
                    line-height: 1.11 !important;
                    letter-spacing: -0.8px !important;
                    max-width: 217px;
                    margin: 0 auto;
                }

                @media(min-width: 850px){
                    font-size: 30px !important;
                    font-weight: 600 !important;
                    font-stretch: normal !important;
                    font-style: normal !important;
                    line-height: 1 !important;
                    letter-spacing: -1.05px !important;
                }
                @media(min-width: 1500px){
                    font-size: 45px !important;
                    line-height: 1.1 !important;
                }
                @media(min-width: 2200px){
                    font-size: 35px !important;
                }
            }

            h2 {
                @include heading-1;
                line-height: 58px;
                @include text-gradient;
                @include text-center;
                margin: 0 0 map-get($pixel, md) + 2px 0;
                letter-spacing: -1.2px;

                @include breakpoint("max-xl") {
                    font-size: 35px;
                    line-height: 42px;
                }

                @include breakpoint("max-md") {
                    @include heading-6;
                }

                @include breakpoint("max-sm") {
                    font-size: 38px !important;
                    font-family: 'Sofia-Regular' !important;
                    font-weight: 600 !important;
                    font-stretch: normal !important;
                    font-style: normal !important;
                    line-height: 1.11 !important;
                    letter-spacing: -0.6px !important;
                }

                @media(min-width: 1500px){
                    font-size: 45px !important;
                    font-weight: 600 !important;
                    font-stretch: normal !important;
                    font-style: normal !important;
                    line-height: 1.1 !important;
                    letter-spacing: -1.05px !important;
                }
                @media(min-width: 2200px){
                    font-size: 35px !important;
                }
            }

            p {
                @include heading-4;
                color: $white-light-color;
                @include text-center;
                font-size: 21px;
                line-height: 33px;
                max-width: 529px;
                margin: 0 auto;

                @include breakpoint("max-xl") {
                    @include text-md-regular;
                    max-width: 495px;
                }

                @include breakpoint("max-md") {
                    line-height: 23px;
                }

                @include breakpoint("max-sm") {
                    font-size: 22px !important;
                    font-weight: 400 !important;
                    font-stretch: normal !important;
                    font-style: normal !important;
                    line-height: 1.11 !important;
                    letter-spacing: -0.6px !important;
                }
            }

            .buttonCenterAlign {
                padding: map-get($pixel, md) * 2 + 2px 0 0 0;
                @include flex-center;
                justify-content: map-get($layout, center);

                >button {
                    padding: map-get($pixel, sm) + 0 map-get($pixel, md) * 3 + 6px;
                    background-color: $bg-white-light-color;
                    color: $base-color-light;
                    border-radius: 9999px;
                    height: 45px;
                    border: none;
                    @include pointer;
                    @include text-md-bold;
                    line-height: normal;
                    transition: .3s ease-in-out;

                    @include breakpoint("max-sm") {
                        min-width: 180px;
                        height: 44px;
                        font-size: 18px;
                        line-height: 5px;
                        letter-spacing: -0.4px;
                        padding: 4px 12px;
                    }

                    &:hover {
                        background-color: $orange-light-color;
                        color: $white-color;
                    }
                }
            }
        }
    }
}



.responsiveBottomSection {
    background: #1d76d9;
    margin-top: -152px;
    z-index: 1;
    position: relative;
    padding: 10px 0 80px 0;
    display: none;

    @include breakpoint("max-sm") {
        display: block;
    }

    .responsiveViewGrid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 40px;
        padding: 0 40px;

        @media(max-width:819px) {
            grid-template-columns: repeat(1, 1fr);
        }

        .firstSection {
            .iconCenterAlignment {
                @include flex-center;
                justify-content: center;
                padding: 0 0 30px 0;

                @include breakpoint("max-sm") {
                    padding: 0 0 20px 0;
                }

                img {
                    @include breakpoint("max-sm") {
                        max-width: 75px;
                    }
                }
            }
        }

        .bottomContentText {
            p {
                @include text-md-regular;
                color: $white-color;
                max-width: 359px;
                margin: 0 auto;
                @include font-normal;
                @include text-center;

                @include breakpoint("max-sm") {
                    font-size: 20px;
                    line-height: 30px;
                    max-width: 250px;
                }
            }
        }
    }
}

.updatedHeroSection {
    width: 100%;
    margin: 60px 0;

    @include breakpoint("max-sm") {
        margin: 40px 0;
    }

    .heroBottomAlignment {
        .bottomSectionAlignment {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 27px;

            @include breakpoint("max-md") {
                grid-template-columns: repeat(2, 1fr);
                gap: 20px;
            }

            @include breakpoint("max-sm") {
                grid-template-columns: repeat(1, 1fr);
                gap: 20px;
            }
        }

        .firstSection {
            background-color: #f5f5f7;
            border-radius: 10px;
            padding: 32px;

            .iconCenterAlignment {
                padding: 0 0 30px 0;
                @include flex-center;
                justify-content: center;

                @include breakpoint("max-sm") {
                    padding: 0 0 20px 0;
                }

                img {
                    max-width: 65px;
                }
            }
        }

        .bottomContentText {
            p {
                @include text-md-regular;
                color: $black-1;
                max-width: 359px;
                @include text-center;
                margin: 0 auto;
                @include font-normal;
            }
        }
    }
}