@import '../../../styles/mixins/global.scss';

.exploreTheBenefitsSection {
    padding: 100px 0;

    @include breakpoint("max-xl") {
        padding: 80px 0;
    }

    @include breakpoint("max-md") {
        padding: 80px 0;
    }

    @include breakpoint("max-sm") {
        padding: 80px 0 80px 0;
    }

    .pageTitle {
        padding: 0 0 55px 0;

        @include breakpoint("max-sm") {
            padding: 0 0 60px 0;
        }

        h1 {
            @include text-5xl;
            color: $black-2;
            max-width: calc(469px);
            line-height: 50px;
            margin: 0 auto;

            @include breakpoint("max-xl") {
                @include heading-small-size;
            }

            @include breakpoint("max-sm") {
                @include heading-6;
                margin: 0 auto;
                max-width: 250px;
                @include text-center;
            }

            a {
                @include text-gradient;
            }
        }
    }

    .grid {
        @include grid-layout;
        grid-template-columns: repeat(3, 1fr);
        gap: 0 85px;

        @include breakpoint("max-md") {
            grid-template-columns: repeat(1, 1fr);
            gap: 40px;
        }

        @include breakpoint("max-sm") {
            grid-template-columns: repeat(1, 1fr);
            gap: 60px;
        }

        .gridItems:first-child {
            p {
                width: calc(400px);

                @include breakpoint("max-xl") {
                    width: calc(311px);
                }

                @include breakpoint("max-sm") {
                    width: 100%;
                    max-width: 283px;
                }
            }
        }

        .gridItems:last-child {
            p {
                width: calc(338px);

                @include breakpoint("max-xl") {
                    width: calc(251px);
                }

                @include breakpoint("max-sm") {
                    width: 100%;
                }
            }
        }

        .gridItems:nth-child(2) {
            p {
                max-width: calc(378px);

                @include breakpoint("max-xl") {
                    max-width: calc(272px);
                }

                @include breakpoint("max-sm") {
                    width: 100%;
                }
            }
        }

        .gridItems {
            .imageCenteralignment {
                height: 249px;
                padding: 0 0 map-get($pixel, md) * 2 + 4px 0;
                max-height: 249px;
                @include flex-center;
                justify-content: map-get($layout, center);

                @include breakpoint("max-sm") {
                    height: 152px;
                    max-width: 281px;
                    padding: 0 0 26px 0;
                    margin: 0 auto;
                }

                img {
                    @include breakpoint("max-sm") {
                        height: 100%;
                        margin: 0 auto;
                    }
                }
            }

            h2 {
                @include heading-3xl;
                color: $black-1;
                margin: 0 0 map-get($pixel, sm) * 3 - 1px 0;
                @include text-center;

                @include breakpoint("max-xl") {
                    font-size: 20px;
                    line-height: 30px;
                    margin: 0 0 10px 0;
                }

                @include breakpoint("max-sm") {
                    @include text-2xl-bold;
                    margin: 0 0 6px 0;
                }
            }

            p {
                @include text-3xl-normal;
                color: $black-1;
                margin: 0 auto;
                @include text-center;

                @include breakpoint("max-xl") {
                    font-size: 16px;
                    line-height: 28px;
                }

                @include breakpoint("max-sm") {
                    @include text-xl-normal;
                    line-height: 30px !important;
                    letter-spacing: -0.44px;
                }
            }
        }

    }
}