@import '../../styles/mixins/global.scss';

.verificationBanner {
    background: url("../../assets/Images/verification-banner.webp");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 803px;
    position: relative;

    @include breakpoint("max-sm") {
        background-image: url("../../assets/Images/verification-banner-mobile.png");
        height: 728px;
    }

    &::before {
        opacity: 0.75;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(4, 62, 127, 0.75) 0%, rgba(47, 146, 255, 0.75) 100%);
        width: 100%;
        height: 100%;
        content: "";
    }

    .verificationContentCenter {
        height: calc(100% - 84px);
        @include flex-center;
        position: map-get($layout, relative);
        justify-content: map-get($layout, center);

        @include breakpoint("max-sm") {
            height: auto;
            padding: 100px 0 0 0;
        }

        .pageTitle {
            p {
                @include text-xl-normal;
                @include text-center;
                line-height: 1.6;
                color: $white-color-opacity;
                max-width: 623px;
                padding: 0 0 map-get($pixel, md) * 3 + 2px 0;
                margin: 0 auto;

                @include breakpoint("max-xl") {
                    max-width: 595px;
                }

                @include breakpoint("max-sm") {
                    @include text-md-regular;
                    max-width: calc(277px);
                }
            }

            .buttonCenterAlign {
                @include flex-center;
                justify-content: map-get($layout, center);
            }

            h1 {
                @include text-center;
                @include heading-lg-2;
                color: $white-color;
                margin: 0 0 map-get($pixel, md) * 3 + 2px 0;

                @include breakpoint("max-xl") {
                    @include heading-small-size;
                    margin: 0 0 26px 0;
                }

                @include breakpoint("max-sm") {
                    @include heading-6;
                    margin: 0 0 24px 0;
                }

                a {
                    @include text-gradient;
                }
            }
        }
    }
}