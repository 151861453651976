@import '../../../styles/mixins/global.scss';

.connectionBlockchainSection {
    background: url("../../../assets/Images/layer.png"), #0076DA;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    padding: 97px 0;

    @include breakpoint("max-sm") {
        padding: 80px 0;
    }

    .connectionBoxCenter {
        @include flex-center;
        justify-content: map-get($layout, center);

        .connectionBox {
            max-width: 785px;

            .barCodeIconAlignment {
                div:first-child {
                    height: 180px;

                    svg {
                        path {
                            width: 45px;
                            height: 45px;
                        }
                    }
                }
            }

            h1 {
                @include text-5xl;
                margin: 0 0 map-get($pixel, md) * 4 - 1px 0;
                color: $white-color;
                @include text-center;
                font-family: 'Sofia-Medium';
                letter-spacing: -0.98px;

                @include breakpoint("max-xl") {
                    @include heading-small-size;
                }

                @include breakpoint("max-sm") {
                    @include heading-6;
                    max-width: 220px;
                    margin: 0 auto;
                }

                a {
                    @include text-gradient;
                    display: block;
                }
            }

            div {
                @include flex-center;
                justify-content: map-get($layout, center);

                @include breakpoint("max-sm") {
                    padding: 27px 0 0 0;
                }

                >button {
                    height: 44px;
                    min-width: 170px;
                    background-color: $white-color;
                    color: $blue-color;
                    @include pointer;
                    border: none;
                    @include text-md-bold;
                    border-radius: 9999px;
                    padding: 2px 5px;
                    transition: .3s ease-in-out;

                    @include breakpoint("max-sm") {
                        @include text-md;
                        @include font-bold;
                        height: 44px;
                        min-width: 152px;
                        padding: 4px 12px;
                    }

                    &:hover {
                        background-color: $orange-light-color;
                        color: $white-color;
                    }
                }
            }
        }
    }
}