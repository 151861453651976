@import '../../../styles/mixins/global.scss';


.blockchainTechnologySection {
    background-color: $bg-light;
    padding: 120px 0;
    margin-top: 72px;

    @include breakpoint("max-xl") {
        padding: 90px 0;
    }

    @include breakpoint("max-md") {
        padding: 120px 0;
    }

    @include breakpoint("max-sm") {
        padding: 60px 0 78px 0;
    }

    .leftWrapper {
        padding-left: max(80px, calc((100vw - 1332px) / 2));

        @include breakpoint("max-md") {
            padding-left: 40px;
        }

        @include breakpoint("max-sm") {
            padding: 0 24px;
        }

        .grid {
            @include grid-layout;
            grid-template-columns: 512px minmax(0, 1fr);
            gap: 0 185px;
            justify-content: space-between;

            @include breakpoint("max-xl") {
                grid-template-columns: repeat(2, 1fr);
                gap: 0 60px;
            }

            @include breakpoint("max-md") {
                grid-template-columns: repeat(1, 1fr);
                gap: 60px;
            }

            @include breakpoint("max-sm") {
                grid-template-columns: repeat(1, 1fr);
                gap: 0;
            }

            .gridItems {
                .iconStyle {
                    padding: 0 0 map-get($pixel, md) * 3 - 1px 0;
                    max-width: 126px;
                }

                .secStep,
                .thirdImage {
                    h2 {
                        @include heading-3xl;
                        letter-spacing: -0.51px;
                        color: $black-1;
                        margin: 0 0 map-get($pixel, md) + 4px 0;

                        @include breakpoint("max-sm") {
                            @include text-2xl;
                            @include font-bold;
                        }

                    }

                    p {
                        @include text-xl-normal;
                        color: $black-1;
                        font-size: 19px;
                        line-height: 36px;
                        letter-spacing: 0px;

                        @include breakpoint("max-sm") {
                            @include text-xl-normal;
                        }
                    }
                }

                .thirdImage {
                    padding: 121px 0 0 0;

                    @include breakpoint("max-xl") {
                        padding: 137px 0 0 0;
                    }

                    @include breakpoint("max-md") {
                        padding: map-get($pixel, md) * 6 0 0 0;
                    }

                    @include breakpoint("max-sm") {
                        padding: map-get($pixel, md) * 6 + 4px 0 0 0;
                    }

                    p {
                        max-width: 640px;

                        @include breakpoint("max-xl") {
                            max-width: 600px;
                        }
                    }

                }

                .firstStep {
                    padding: map-get($pixel, md) * 8 + 7px 0 map-get($pixel, md) * 14 + 7px 0;

                    @include breakpoint("max-xl") {
                        padding: 50px 0 70px 0;
                    }

                    @include breakpoint("max-md") {
                        padding: map-get($pixel, md) * 6 0;
                    }

                    @include breakpoint("max-sm") {
                        padding: 74px 0 64px 0;
                    }

                    h2 {
                        @include heading-3xl;
                        color: $black-1;
                        margin: 0 0 map-get($pixel, md) + 4px 0;

                        @include breakpoint("max-sm") {
                            @include text-2xl;
                            @include font-bold;
                        }
                    }

                    p {
                        @include text-xl-normal;
                        color: $black-1;
                        line-height: 1.8;
                        letter-spacing: 0px;

                        @include breakpoint("max-xl") {
                            max-width: 511px;
                        }

                        @include breakpoint("max-sm") {
                            @include text-xl-normal;
                        }
                    }
                }

                h1 {
                    @include text-5xl;
                    max-width: 415px;
                    color: $black-1;
                    margin: 0 0 map-get($pixel, md) 0;

                    @include breakpoint("max-xl") {
                        @include heading-small-size;
                        max-width: 350px;
                    }

                    @include breakpoint("max-sm") {
                        @include heading-6;
                    }

                    a {
                        @include text-gradient;
                    }
                }

                p {
                    @include text-xl-normal;
                    line-height: 36px;
                    color: $black-1;
                    margin: 0;

                    @include breakpoint("max-xl") {
                        max-width: 471px;
                        margin: 20px 0 0 0;
                    }

                    @include breakpoint("max-sm") {
                        @include text-xl;
                        @include font-normal;
                        font-family: 'Sofia-Regular';
                    }
                }

                .blockchainImage {
                    width: 100%;
                    border-radius: 40px 0 0 40px;
                    position: relative;

                    @include breakpoint("max-md") {
                        display: none;
                    }

                    @include breakpoint("max-sm") {
                        display: none;
                    }

                    div {
                        height: 690px;
                        border-radius: 40px 0 0 40px;
                        width: 100%;

                        @include breakpoint("max-xl") {
                            height: 527px;
                        }
                    }
                }
            }

        }
    }
}

.mobileViewImageShow {
    padding: 60px 0 0 0;
    display: none;

    @include breakpoint("max-md") {
        display: block;
    }

    .mobileViewStyle {
        width: 100%;
        height: 490px;
        position: relative;

        div {
            @include breakpoint("max-md") {
                height: 100%;
            }
        }

        video {
            @include breakpoint("max-md") {
                display: none;
            }
        }

        img {
            display: none;

            @include breakpoint("max-md") {
                display: block;
            }
        }

        video,
        img {
            position: absolute;
            top: 0;
            object-fit: cover;
            left: 0;
            width: 100%;
            border-radius: 16px 0 0 16px;
            height: 100%;
        }

        @include breakpoint("max-sm") {
            height: 258px;
            border-radius: 0px;
        }
    }
}