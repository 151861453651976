@import '../../../../styles/mixins/global.scss';

.joinCommunityBanner {
    background-image: url("../../../../assets/Images/new-vector.png"), linear-gradient(to right, #3ba6e4 -30%, #0c64c6 100%);
    width: 100%;
    background-repeat: no-repeat;
    padding: map-get($pixel, md) * 14 + 4px 0;
    background-size: cover;

    @include breakpoint("max-sm") {
        padding: 160px 0;
    }

    .joinCommunityBoxCenter {
        @include flex-center;
        justify-content: map-get($layout, center);
    }

    .joinCommunityBox {
        width: 702px;

        h1 {
            @include text-5xl;
            @include text-center;
            margin: 0 0 15px 0;
            font-size: 44px;
            line-height: 64px;
            @include text-gradient;

            @include breakpoint("max-sm") {
                font-size: 46px;
                @include text-center;
                line-height: 54px;
                letter-spacing: -1.02px;
                margin: 0 0 12px 0;
                @include font-medium;
            }
        }

        .input {
            max-width: 550px;
            position: relative;
            margin: 0 auto;

            .signupButton {
                position: absolute;
                top: 0;
                right: 0;

                button {
                    @include text-sm;
                    height: 54px;
                    width: 115px;
                    background-color: $dark-black;
                    color: $white-color;
                    border: none;
                    @include pointer;
                    border-radius: 0 6px 6px 0;
                }
            }

            input {
                width: 100%;
                background-color: $white-color;
                border: none;
                border-radius: map-get($pixel, md) - 4px;
                height: 54px;
                padding: 0 120px 0 14px;
                @include text;
                color: $light-gray-200;
            }

            ::placeholder {
                @include text;
                color: $light-gray-200;
            }
        }

        p {
            @include text-xl-medium;
            color: $white-color;
            margin: 0 0 30px 0;
            @include text-center;

            @include breakpoint("max-sm") {
                font-size: 24px;
                text-align: center;
                margin: 0 0 26px 0;
            }
        }
    }
}