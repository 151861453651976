@import '../../../styles/mixins/global.scss';

.automateBusinessBanner {
    background-image: url("../../../assets/Images/automateBusiness.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    padding: 167px 0;

    @include breakpoint("max-sm") {
        background-image: url("../../../assets/Images/automateBusiness-mobile.jpg");
        height: 773px;
        padding: 120px 0 0 0;

    }

    div:first-child {
        div {
            @include breakpoint("max-sm") {
                @include flex-center;
                justify-content: center;
            }
        }
    }

    h1 {
        @include text-5xl;
        color: $white-color;
        max-width: 586px;
        margin: 0 0 14px 0;

        @include breakpoint("max-xl") {
            @include heading-small-size;
        }

        @include breakpoint("max-sm") {
            @include heading-6;
            @include text-center;
            margin: 0 auto;
            max-width: calc(246px);
            margin-bottom: 45px;
        }

        a {
            @include text-gradient;
        }
    }
}