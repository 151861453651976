$base-color:#026ddb;
$blue-color:#1d76d9;
$blue-color-100:#1d76d9;
$blue-color-light-400:#0076da;
$base-bg-light:#d4eafd;
$base-color-light:#0474dc;
$white-color:#fff;
$white-color-opacity:#f9f9f9;
$orange-color:#ff9e1c;
$orange-color-4:#f06e00;
$border-color-2:#d9d9d9;
$orange-light-color:#ff8e20;
$orange-color-light:#ea7d22;
$white-light-color:#f8f6f0;
$bg-white-light-color:#f9f5f1;
$bg-light:#fffbf7;
$black-1:#161616;
$black-3:#303030;
$black-1-light:#151515;
$black-2:#2b2b2b;
$dark-black:#000000;
$bg-light-200:#f5f5f7;
$light-gray-text:#989898;
$light-gray-200:#989898;
$light-border:#c0c0c0;
$card-background:#437db9;
$modal-background-color:#ebebeb;
$placeholder-color:#989898;

$blue-gradient:linear-gradient(296deg, #043e7f 100%, #2f92ff);