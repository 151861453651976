@import '../../../styles/mixins/global.scss';

.paymentServiceSectionAlignment {
    padding: 100px 0;

    @include breakpoint("max-xl") {
        padding: 80px 0;
    }

    @include breakpoint("max-md") {
        padding: 80px 0;
    }

    @include breakpoint("max-sm") {
        padding: 51px 0 51px 0;
    }

    .grid {
        @include grid-layout;
        grid-template-columns: 577px minmax(0, 1fr);
        align-items: center;
        gap: 0 215px;

        @include breakpoint("max-xl") {
            gap: 0 150px;
        }

        @include breakpoint("max-md") {
            grid-template-columns: repeat(1, 1fr);
            gap: 60px;
        }

        @include breakpoint("max-sm") {
            grid-template-columns: repeat(1, 1fr);
            gap: 50px;
        }

        .gridItems {
            p {
                @include breakpoint("max-sm") {
                    max-width: 327px;
                }
            }

            .currencyImagesAlignment {
                position: relative;

                .detailsImageAlignment {
                    position: absolute;
                    top: 15%;
                    right: 5%;
                    border-radius: 26.5px;
                    box-shadow: 2px 2px 25px 8px rgba(131, 131, 131, 0.08);
                    background-color: #fff;
                    width: 50%;

                    img {
                        width: 100%;
                    }

                    @media(min-width: 1500px) {
                        top: 10%;
                        width: 52%;
                        right: 0%;
                    }
                }
            }

        }
    }
}

.keepSalesSection {
    .grid {
        @include grid-layout;
        grid-template-columns: 500px minmax(0, 1fr);
        gap: 0 130px;
        align-items: center;

        @include breakpoint("max-md") {
            grid-template-columns: repeat(1, 1fr);
            gap: 60px;
        }

        @include breakpoint("max-sm") {
            grid-template-columns: repeat(1, 1fr);
            gap: 24px;
        }

        .gridItems:nth-child(1) {
            @include breakpoint("max-sm") {
                order: 2;
            }
        }

        .gridItems {
            h1 {
                max-width: 425px;

                @include breakpoint("max-xl") {
                    @include heading-small-size;
                    max-width: 400px;
                }
            }

            .imageStyle {
                max-width: 100%;
            }

            p {
                max-width: calc(450px);

                @include breakpoint("max-xl") {
                    max-width: calc(437px);
                }

                @include breakpoint("max-sm") {
                    max-width: calc(327px);
                }
            }
        }
    }
}

.sameTextGroupStyle {
    h1 {
        @include text-5xl;
        max-width: 450px;
        color: $black-2;
        line-height: 50px;
        margin: 0 0 map-get($pixel, md) * 2 0;

        @include breakpoint("max-xl") {
            @include heading-small-size;
            max-width: 410px;
        }

        @include breakpoint("max-sm") {
            @include text-2xl-bold;
            margin: 0 0 10px 0;
            max-width: 300px;
        }
    }

    p {
        @include text-20xl;
        max-width: 450px;
        color: $black-2;

        @include breakpoint("max-xl") {
            max-width: 433px;
        }
    }
}

.instantTranactions {
    padding: 120px 0;

    @include breakpoint("max-xl") {
        padding: 90px 0;
    }

    @include breakpoint("max-md") {
        padding: 80px 0;
    }

    @include breakpoint("max-sm") {
        padding: 51px 0 80px 0;
    }

    .grid {
        @include grid-layout;
        grid-template-columns: minmax(0, 1fr) 500px;
        gap: 0 200px;

        @include breakpoint("max-md") {
            grid-template-columns: repeat(1, 1fr);
            gap: 60px;
        }

        @include breakpoint("max-sm") {
            grid-template-columns: repeat(1, 1fr);
            gap: 24px;
        }

        .gridItems {
            p {
                max-width: calc(450px);

                @include breakpoint("max-xl") {
                    max-width: calc(415px);
                }

                @include breakpoint("max-sm") {
                    max-width: calc(330px);
                }
            }
        }
    }
}