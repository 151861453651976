@import '../../../styles/mixins/global.scss';

.notableDataSection {
    background-color: $blue-color-light-400;
    padding: 60px 0 80px 0;

    h1 {
        @include heading-5xl;
        color: $white-color;
        margin: 0 0 76px 0;
        @include text-center;

        @include breakpoint("max-xl") {
            @include heading-small-size;
            font-family: 'Sofia-Medium';
            margin: 0 0 60px 0;

        }

        @include breakpoint("max-sm") {
            @include heading-6;
            margin: 0 0 55px 0;
        }
    }

    .counterCenterAlignment {
        @include flex-center;
        justify-content: map-get($layout, center);

        @include breakpoint("max-md") {
            @include grid-layout;
            grid-template-columns: repeat(2, 1fr);
            gap: 40px;
        }

        @include breakpoint("max-sm") {
            @include grid-layout;
            grid-template-columns: repeat(1, 1fr);
            gap: 60px;
        }

        div:last-child {
            margin: 0;
        }

        div {
            margin-right: 143px;

            @include breakpoint("max-md") {
                margin: 0;
            }

            h2 {
                @include heading-2xlarge;
                @include text-center;
                margin: 0 0 20px 0;
                color: $white-color;

                @include breakpoint("max-xl") {
                    font-size: 60px;
                    line-height: 60px;
                    margin: 0 0 10px 0;
                }

                @include breakpoint("max-sm") {
                    font-size: 87px;
                    line-height: 87px;
                    letter-spacing: -1.93px;
                }
            }

            span {
                @include text-2xl;
                display: block;
                @include text-center;
                letter-spacing: -0.51px;
                color: $white-color;
            }
        }
    }

}