// @use  '';
@use "../mixins/breakpoint" as bp;

@import '../mixins/breakpoint';



.container {
  width: 100%;
  padding: 0 8.8%;
    margin: 0 auto;
    max-width: 100%;
    @media screen and (max-width: 1600px) {
      padding: 0 80px ;
    }
    @media screen and (max-width: 1320px) {
      padding: 0 40px;
    }
    @media screen and (max-width: 767px) {
      padding: 0 24px;
    }
}

.container-md {  
    max-width : 1332px;
    padding: 0 20px;
    margin: 0 auto;
    @media screen and (max-width: 1600px) {
      padding: 0 80px;
    }
    @media screen and (max-width: 1320px) {
      padding: 0 40px;
    }
    @media screen and (max-width: 767px) {
      padding: 0 24px;
    }
}

.container-lg {  
    max-width : 1382px + 40px;
    padding: 0 20px;
    margin: 0 auto;
    @media screen and (max-width: 1600px) {
      padding: 0 80px;
    }
    @media screen and (max-width: 1320px) {
      padding: 0 40px;
    }
    @media screen and (max-width: 767px) {
      padding: 0 24px;
    }
}

.container-fluid {
  max-width: 100%;
  padding: 0 56px;
  margin: 0 auto;
  @media screen and (max-width: 1600px) {
    padding: 0 100px;
  }
  @media screen and (max-width: 1320px) {
    padding: 0 40px;
  }
  @media screen and (max-width: 767px) {
    padding: 0 24px;
  }
}
  

