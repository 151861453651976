@import '../../../styles/mixins/global.scss';

.platformsMymarkit {
    background-color: $bg-white-light-color;
    padding: 100px 0;

    @include breakpoint("max-xl") {
        padding: 80px 0;
    }

    @include breakpoint("max-sm") {
        padding: 57px 0 60px 0;
    }

    .plaeformAlignment {
        padding: 0 40px;

        @include breakpoint("max-sm") {
            padding: 0;
        }

        .platformsImageAlignment {
            @include flex-center;
            justify-content: space-between;

            div {
                img {
                    @include pointer;
                }
            }
        }

        .textGrid {
            @include grid-layout;
            grid-template-columns: minmax(0, 1fr) 504px;
            gap: 0 139px;
            justify-content: space-between;

            @include breakpoint("max-md") {
                grid-template-columns: repeat(1, 1fr);
                gap: 40px;
            }

            @include breakpoint("max-sm") {
                grid-template-columns: repeat(1, 1fr);
                gap: 24px;
            }

            .textGridItems {
                color: $black-1;

                h1 {
                    @include text-5xl;
                    letter-spacing: -0.98px;
                    line-height: 55px;
                    margin: 0;

                    @include breakpoint("max-xl") {
                        @include heading-small-size;
                    }

                    @include breakpoint("max-sm") {
                        @include heading-6;
                    }
                }

                p {
                    @include text-xl-normal;
                    line-height: 36px;
                    letter-spacing: 0px;

                    @include breakpoint("max-xl") {
                        line-height: 30px !important;
                        max-width: 450px;
                    }

                    @include breakpoint("max-sm") {
                        @include text;
                        max-width: 287px;
                        letter-spacing: 0px;
                    }
                }
            }
        }
    }
}