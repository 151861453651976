@import '../../../styles/mixins/global.scss';

.powerOfBlockchainBannner {
    background-image: url("../../../assets/Images/blue-vectore.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    padding: 167px 0;

    @include breakpoint("max-sm") {
        padding: 228px 0;
        background-position: center center;
    }

    h1 {
        max-width: calc(586px);
        @include text-5xl;
        margin: 0 auto;
        @include text-center;
        padding: 0 0 50px 0;
        font-family: 'Sofia-Medium';

        @include breakpoint("max-xl") {
            @include heading-small-size;
            font-family: 'Sofia-Medium';
        }

        color: $white-color;

        @include breakpoint("max-sm") {
            @include heading-6;
            max-width: calc(216px);
            padding: 0 0 36px 0;
        }

        a {
            @include text-gradient;
        }
    }

    .buttonCenteralign {
        @include flex-center;
        justify-content: map-get($layout, center);
    }
}