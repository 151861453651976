.loader-alignment {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background-color: #f9f5f1;

}