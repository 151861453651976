@import '../../../styles/mixins/global.scss';

.secrollBlackHeaderSticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;
    transition: .3s ease-in-out;
    background-color: $white-color;

    .blackHeader {
        background-color: $white-color;
        padding: map-get($pixel, md) * 2 + 8px 0;
        box-shadow: 0 2px 26px 0 rgba(110,110,110,0.08);

        @include breakpoint("max-xl") {
            padding: 22px 0;
        }

        .headerAlignment {
            @include flex-between;

            .logo {
                img {
                    @include pointer;
                    max-width: 150px;

                    @include breakpoint("max-xl") {
                        max-width: 155px;
                    }
                }

                h1 {
                    @include heading-large;
                    user-select: none;
                    @include pointer;
                    color: $black-1;

                    @include breakpoint("max-sm") {
                        font-size: 30px;
                    }
                }
            }

            .mobileMenu {
                display: none;

                @include breakpoint("max-md") {
                    display: block;
                }
            }

            .menu {
                @include breakpoint("max-md") {
                    display: none;
                }

                nav {
                    ul {
                        @include flex-center;
                        margin: 0;
                        padding: 0;

                        li:last-child {
                            margin: 0;
                        }

                        li {
                            color: $black-1;
                            @include text-sm;
                            @include font-medium;
                            @include relative;
                            text-transform: uppercase;
                            margin-right: 56px;
                            @include pointer;

                            @include breakpoint("max-xl") {
                                font-size: 14px;
                                line-height: 20px;
                                font-family: 'Sofia-Medium';
                                font-weight: 500;
                                margin-right: 40px;
                            }

                            span {
                                padding-right: map-get($pixel, sm) * 2;
                            }

                            i {
                                color: $black-1;
                            }
                        }
                    }
                }
            }
        }
    }
}



.mobileSidebarWrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    z-index: 99999;
}

.mobileViewSidebar {
    position: fixed;
    top: 0;
    right: 0;
    width: 375px;
    height: 100vh;
    z-index: 9999999;
    background-color: $blue-color-100;

    @include breakpoint("max-sm") {
        width: 100%;
    }

    .mobileHeader {
        padding: 30px;
        @include flex-center;
        justify-content: space-between;

        div {
            img {
                @include pointer;
            }
        }
    }

    .mobileBody {
        padding: 0 30px;

        .leftSideAlignment {
            padding-left: 25px;
        }

        a {
            @include text-md;
            letter-spacing: 0px;
            display: block;
            padding: 0 0 25px 0;
            @include pointer;
            color: $white-color;
        }
    }
}

.sidebarHidden {
    transform: translateX(100%);
    transition: .3s ease-in-out;
}

.sidebarShow {
    transform: translateX(0%);
    transition: .3s ease-in-out;
}