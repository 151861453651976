@import '../../styles/mixins/global.scss';

.blockchainMarketplaceBanner {
    background-image: url("../../assets/Images/blockchainMarketplaceBanner.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 850px;

    @include breakpoint("max-xl") {
        background-position: center;
    }

    @include breakpoint("max-sm") {
        background-position: center center;
        height: auto;
    }

    .BlockchainMarketplaceHeader {
        backdrop-filter: blur(10px);
        background-color: rgba(2, 14, 35, 0.32);
    }

    .blockchainMarketplaceContent {
        padding: 174px 0 0 0;

        @include breakpoint("max-sm") {
            padding: 60px 0 198px 0;
        }

        diV:first-child {
            div {
                @include breakpoint("max-sm") {
                    @include flex-center;
                    padding: 40px 0 0 0;
                    justify-content: center;
                }
            }
        }

        h1 {
            @include heading-lg-2;
            color: $white-color;
            max-width: calc(364px);
            margin: 0 0 14px 0;

            @include breakpoint("max-xl") {
                @include heading-small-size;
            }

            @include breakpoint("max-sm") {
                @include heading-6;
                margin: 0 auto;
                max-width: 250px;
                padding: 0 0 24px 0;
                @include text-center;
            }

            a {
                @include text-gradient;
            }
        }

        p {
            @include text-20xl;
            color: $white-color;
            max-width: calc(504px);
            margin: 0 0 34px 0;

            @include breakpoint("max-xl") {
                max-width: calc(480px);
                @include text-md-regular;
                line-height: 30px;
                max-width: 435px;
            }

            @include breakpoint("max-md") {
                @include text-md-regular;
                max-width: calc(277px);
                margin: 0 0 30px 0;
                @include text-center;
            }
        }
    }
}