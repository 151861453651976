
.input {
    .input-same-style {
        background-color: $white-color;
        border-radius: 6px;
        border: none;
        padding: 0 13px;
        @include text;
        color: $black-1;
    }
    input {
        width: 100%;
        height: 54px;
        @extend .input-same-style;
       
    }
    textarea {
        width: 100%;
        height: 168px;
        @extend .input-same-style;
        padding:13px;
    }
    ::placeholder {
        @include text;
        color: $light-gray-text;
    }
}

