@keyframes jackInTheBox {
    0% {
        opacity: 0;
        transform: scale(.1) rotate(30deg);
        transform-origin: center bottom
    }

    50% {
        transform: rotate(-10deg)
    }

    70% {
        transform: rotate(3deg)
    }

    to {
        opacity: 1;
        transform: scale(1)
    }
}