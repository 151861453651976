@import '../../../styles/mixins/global.scss';

.futureOfShoppingBanner {
    background-image: url("../../../assets/Images/future-shopping-banner.webp");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 180px 0 175px 0;
    width: 100%;

    @include breakpoint("max-sm") {
        padding: 217px 0;
        background-position: center center;
    }

    h1 {
        @include text-5xl;
        @include text-center;
        margin: 0 0 25px 0;
        line-height: 50px;
        color: $white-color;

        @include breakpoint("max-xl") {
            @include heading-small-size;
        }

        @include breakpoint("max-sm") {
            @include heading-6;
            max-width: 287px;
            margin: 0 auto 40px auto;
        }

        a {
            @include text-gradient;
        }
    }

    .buttonCenterAlignment {
        @include flex-center;
        justify-content: map-get($layout, center);

        div {
            button {
                @include breakpoint("max-sm") {
                    min-width: 198px;
                }
            }
        }
    }
}