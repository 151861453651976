@import '../../../styles/mixins/global.scss';

.paymentMethodsBanner {
    background-image: url("../../../assets/Images/paymentMethodsBanner.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    padding: 138px 0 181px 0;

    @include breakpoint("max-xl") {
        padding: 100px 0 100px 0;
        background-position: bottom;
    }

    @include breakpoint("max-sm") {
        padding: 57px 0 112px 0;
        background-position: center center;
    }

    h1 {
        @include text-5xl;
        line-height: 50px;
        color: $white-color;
        margin: 0 auto;
        @include text-center;
        max-width: calc(600px);
        padding: 0 0 120px 0;

        @include breakpoint("max-xl") {
            @include heading-small-size;
            max-width: 520px;
        }

        @include breakpoint("max-sm") {
            @include heading-6;
            padding: 0 20px 100px 20px;

        }

        a {
            @include text-gradient;
        }
    }

    .boxCenterAlignment {
        @include flex-center;
        justify-content: center;

        .box {
            width: 400px;

            .bitMapIcon {
                width: 72px;
                height: 65px;
            }

            .roundIcon {
                width: 133px;
                height: 133px;
                background-color: rgba(255, 255, 255, .4);
                @include flex-center;
                justify-content: center;
                border-radius: 50%;

                img {
                    @include breakpoint("max-xl") {
                        max-width: 35px;
                    }
                }

                @include breakpoint("max-xl") {
                    width: 100px;
                    height: 100px;
                }

                @include breakpoint("max-sm") {
                    width: 105px;
                    height: 105px;
                }
            }

            .secRowAlignment {
                display: map-get($layout, flex);
                justify-content: center;

                .roundIcon:nth-child(2) {
                    margin-top: 65px;
                    margin-left: -36px;

                    @include breakpoint("max-xl") {
                        margin-top: 40px;
                        margin-left: -30px;
                    }
                }

                .roundIcon:last-child {
                    margin-left: -36px;

                    @include breakpoint("max-xl") {
                        margin-left: -30px;
                    }
                }
            }

            .firstRowAlignment {
                display: map-get($layout, flex);
                justify-content: center;
                margin: 0 0 60px 0;

                .roundIcon:first-child {
                    img {
                        @include breakpoint("max-xl") {
                            max-width: 50px;
                        }
                    }
                }

                .roundIcon:nth-child(2) {
                    margin-top: -65px;
                    margin-left: -36px;

                    @include breakpoint("max-xl") {
                        margin-top: -40px;
                        margin-left: -30px;
                    }
                }

                .roundIcon:last-child {
                    margin-left: -36px;

                    @include breakpoint("max-xl") {
                        margin-left: -30px;
                    }
                }
            }
        }
    }
}