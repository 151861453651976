@use '../mixins/breakpoint.scss';

// @import url("http://fonts.cdnfonts.com/css/sofia-pro");

@import url("http://fonts.cdnfonts.com/css/fertigo");

// font-family: 'fertigo-pro-regular', sans-serif;

@mixin heading-large {
    font-size: calc(40px - 37%);
    line-height: calc(48px - 37%);
    font-family: 'Fertigo_PRO';
    @include font-normal;
    @include breakpoint("max-xl") {
        font-size: calc(40px - 45%);
        line-height: calc(48px - 36%);
    }
}

@mixin heading-1 {
    font-size: calc(54px - 60%);
    font-family: 'Sofia-Bold';
    @include font-bold;
    line-height: calc(64px - 60%);
    letter-spacing: -0.98px;
    @include breakpoint("max-xl") {
    font-size: calc(54px - 45%);
    line-height: calc(64px - 36%);
    }
}

@mixin heading-5xl {
    font-size: calc(51px - 37%);
    font-family: 'Sofia-Bold';
    @include font-bold;
    line-height: calc(64px - 37%);
    letter-spacing: -1.13px;
    @include breakpoint("max-xl") {
        font-size: calc(51px - 45%);
        line-height: calc(64px - 36%);

    }
}

@mixin heading-2xlarge {
    font-size: calc(87px - 37%);
    font-family: 'Sofia-Bold';
    @include font-bold;
    line-height: calc(87px - 37%);
    letter-spacing: -1.93px;
    @include breakpoint("max-xl")  {
        font-size: calc(87px - 45%);
        line-height: calc(87px - 36%);
    }
  
}

@mixin heading-2lg {
    font-size: calc(80px - 37%);
    font-family: 'Sofia-Bold';
    @include font-bold;
    line-height: calc(92px - 37%);
    letter-spacing: -1.78px;
    @include breakpoint("max-xl") {
        font-size: calc(80px - 45%);
        line-height: calc(92px - 36%);

    }
 
}

@mixin heading-3lg {
    font-size: calc(72px - 37%);
    font-family: 'Sofia-Medium';
    @include font-medium;
    line-height: normal;
    letter-spacing: -1.78px;
    @include breakpoint("max-xl")  {
    font-size: calc(72px - 45%);

    }
}

@mixin  heading-3lg-bold {
    font-size: calc(72px - 37%);
    font-family: 'Sofia-Bold';
    @include font-bold;
    line-height: normal;
    letter-spacing: -1.78px;
    @include breakpoint("max-xl") {
    font-size: calc(72px - 45%);
    }
}

@mixin heading-lg {
    font-size: calc(60px - 37%);
    font-family: 'Sofia-Bold';
    @include font-bold;
    line-height: calc(68px - 37%);
    letter-spacing: -1.33px;
    @include breakpoint("max-xl")  {
        font-size: calc(60px - 45%);
    }
  
}

@mixin heading-lg-2 {
    font-size: calc(58px - 37%);
    font-family: 'Sofia-Bold';
    @include font-bold;
    line-height: calc(68px - 37%);
    letter-spacing: -0.6px;
    @include breakpoint("max-xl")  {
    font-size: calc(58px - 45%);
    line-height: calc(68px - 36%);
    }
}

@mixin heading-3 {
    font-size: 32px;
    font-family: 'Sofia-Bold';
    @include font-bold;
    line-height: 38px;
    letter-spacing: -0.58px;
    @include breakpoint("max-xl")  {
        font-size: 30px;
        line-height: 36px;
    }
}

@mixin heading-3-xl {
    font-size: 36px;
    font-family: 'Sofia-Bold';
    @include font-bold;
    line-height: 44px;
    letter-spacing: -0.58px;
    @include breakpoint("max-xl")  {
        font-size: 30px;
        line-height: 36px;
    }
}

@mixin heading-3-medium {
    font-size: 32px;
    font-family: 'Sofia-Medium';
    @include font-medium;
    line-height: 38px;
    letter-spacing: -0.58px;
    @include breakpoint("max-xl")  {
        font-size: 30px;
        line-height: 36px;
    }
   
}

@mixin heading-4 {
    font-size: 26px;
    font-family: 'Sofia-Regular';
    @include font-normal;
    line-height: 36px;
    letter-spacing: -0.98px;
    @include breakpoint("max-xl")  {
        font-size: 24px;
        line-height: 34px;
    }
}

@mixin heading-6 {
    font-size: 34px;
    font-family: 'Sofia-Bold';
    @include font-bold;
    line-height: 40px;
    letter-spacing: -0.8px;
    @include breakpoint("max-xl") {
        font-size: 30px;
        line-height: 42px;
    }
    @include breakpoint("max-sm") {
        line-height: 36px;
    }
}

@mixin text-xl-medium {
    font-size: 20px;
    font-family: 'Sofia-Medium';
    @include font-medium;
    line-height: 26px;
    letter-spacing: -0.44px;
    @include breakpoint("max-xl") {
        font-size: 19px;
        line-height: 35px;
    }
}

@mixin text-xl {
    font-size: 20px;
    font-family: 'Sofia-Bold';
    @include font-bold;
    line-height: 26px;
    letter-spacing: 0.38px;
    @include breakpoint("max-xl") {
        font-size: 19px;
        line-height: 25px;
    }
}

@mixin text-xl-normal {
    font-size: 20px;
    font-family: 'Sofia-Regular';
    @include font-normal;
    line-height: 26px;
    letter-spacing: 0.38px;
    @include breakpoint("max-xl") {
        font-size: 18px;
        line-height: 32px;
    }
}

@mixin text-20xl {
    font-size: 20px;
    font-family: 'Sofia-Regular';
    @include font-normal;
    line-height: 36px;
    letter-spacing: 0;
    @include breakpoint("max-xl") {
        font-size: 19px;
        line-height: 35px;
    }
}

@mixin text-2xl {
    font-size: 23px;
    font-family: 'Sofia-Medium';
    @include font-medium;
    line-height: 35px;
    @include breakpoint("max-xl") {
        font-size: 22px;
        line-height: 35px;
    }
  
}

@mixin text-2xl-bold {
    font-size: 23px;
    font-family: 'Sofia-Bold';
    @include font-bold;
    line-height: 32px;
    letter-spacing: -0.53px;
    @include breakpoint("max-xl") {
        font-size: 22px;
        line-height: 31px;
    }
}

@mixin text-2xl-regular {
    font-size: 23px;
    font-family: 'Sofia-Regular';
    @include font-normal;
    line-height: 36px;
    @include breakpoint("max-xl") {
        font-size: 22px;
        line-height: 35px;
    }
  
}

@mixin text-3xl {
    font-size: 21px;
    font-family: 'Sofia-Medium';
    @include font-medium;
    line-height: 33px;
    @include breakpoint("max-xl") {
        font-size: 20px;
        line-height: 30px;
    }
}

@mixin text-3xl-normal {
    font-size: 21px;
    font-family: 'Sofia-Regular';
    @include font-normal;
    line-height: 33px;
}

@mixin text-4xl {
    font-size: 35px;
    font-family: 'Sofia-Medium';
    @include font-medium;
    letter-spacing: -0.67px;
    line-height: 42px;
    @include breakpoint("max-xl") {
        font-size: 32px;
        line-height: 40px;
    }

}

@mixin text-5xl {
    font-size: calc(44px - 37%);
    font-family: 'Sofia-Bold';
    @include font-medium;
    letter-spacing: -0.98px;
    line-height: calc(68px - 37%);
    @include breakpoint("max-xl") {
        font-size: calc(44px - 33%);
        line-height: calc(68px - 50%);

    }

}

@mixin heading-3xl {
    font-size: 25px;
    font-family: 'Sofia-Bold';
    @include font-bold;
    line-height: 34px;
  
}

@mixin text-3xl-regular {
    font-size: 25px;
    font-family: 'Sofia-Regular';
    @include font-normal;
    line-height: 32px;
  
}

@mixin heading-small-size {
    font-size: 35px;
    @include font-normal;
    line-height: 45px;
    font-family: "Sofia-Bold";
    font-weight: 500;
    letter-spacing: -0.98px;
  
}

@mixin text-md {
    font-size: 18px;
    font-family: 'Sofia-Medium';
    @include font-medium;
    line-height: 26px;
    letter-spacing: -0.4px;
  
}

@mixin text-md-regular {
    font-size: 18px;
    font-family: 'Sofia-Regular';
    @include font-normal;
    line-height: 26px;
    letter-spacing: -0.4px;
    @include breakpoint("max-md") {
        font-size: 16px;
        line-height: 26px;
    }
}

@mixin text-md-bold {
    font-size: 18px;
    font-family: 'Sofia-Bold';
    @include font-bold;
    line-height: 26px;

}

@mixin text-bold {
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.3px;
    font-family: 'Sofia-Bold';
    @include font-bold;
}

@mixin text {
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.3px;
    font-family: 'Sofia-Regular';
    @include font-normal;
}

@mixin text-small {
    font-size: 12px;
    line-height: 25px;
    letter-spacing: 0.13px;
    font-family: 'Sofia-Medium';
    @include font-medium;
}

@mixin text-sm {
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0.28px;
    font-family: 'Sofia-Regular';
    @include font-normal;
}

