@import '../../../styles/mixins/global.scss';

.processTypeDetailsSection {
    padding: 100px 0 0 0;

    @include breakpoint("max-xl") {
        padding: 80px 0 0 0;
    }

    @include breakpoint("max-sm") {
        padding: 50px 0 0 0;
    }

    .typeDetailsText {
        h1 {
            @include text-5xl;
            color: $black-1;
            margin: 0 0 20px 0;
            max-width: 542px;

            @include breakpoint("max-xl") {
                max-width: 520px;
                @include heading-small-size;
            }

            @include breakpoint("max-sm") {
                @include text-2xl-bold;
                margin: 0 0 10px 0;
            }
        }

        p {
            @include text-xl-normal;
            line-height: 36px;
            letter-spacing: 0px;
            color: $black-2;
            max-width: 436px;

            @include breakpoint("max-xl") {
                line-height: 30px !important;
            }

            @include breakpoint("max-sm") {
                @include text-xl-normal;
            }
        }
    }

    .mindLedgersGrid {
        @include grid-layout;
        grid-template-columns: 626px minmax(0, 1fr);
        gap: 0 156px;
        padding: 0 0 100px 0;
        align-items: center;

        @include breakpoint("max-xl") {
            grid-template-columns: repeat(2, 1fr);
            gap: 0 120px;
        }

        @include breakpoint("max-md") {
            grid-template-columns: repeat(1, 1fr);
            gap: 40px;
            padding: 0 0 67px 0;
        }

        @include breakpoint("max-sm") {
            grid-template-columns: repeat(1, 1fr);
            gap: 40px;
            padding: 0 0 67px 0;
        }

        .mindLedgersGridItems {
            h1 {
                max-width: 450px;
            }

            .typeDetailsText {
                p {
                    max-width: 500px;

                    @include breakpoint("max-xl") {
                        max-width: 450px;
                    }
                }
            }
        }
    }

    .utilizeGrid {
        @include grid-layout;
        grid-template-columns: minmax(0, 1fr) 611px;
        gap: 0 181px;
        align-items: center;
        padding: 0 0 100px 0;

        @include breakpoint("max-xl") {
            grid-template-columns: repeat(2, 1fr);
            gap: 0 120px;
        }

        @include breakpoint("max-md") {
            grid-template-columns: repeat(1, 1fr);
            gap: 40px;
            padding: 0 0 40px 0;

        }

        @include breakpoint("max-sm") {
            grid-template-columns: repeat(1, 1fr);
            gap: 40px;
            padding: 0 0 40px 0;

        }

        h1 {
            @include breakpoint("max-xl") {
                max-width: 450px;
            }
        }

        p {
            @include breakpoint("max-xl") {
                max-width: 450px;
            }
        }

        .utilizeGridItems:nth-child(1) {
            @include breakpoint("max-md") {
                order: 2;
            }
        }

        .utilizeGridItems:nth-child(1) {
            @include breakpoint("max-sm") {
                order: 2;
            }
        }

        .utilizeGridItems {
            .typeDetailsText {
                p {
                    max-width: 500px;

                    @include breakpoint("max-xl") {

                        max-width: 445px;

                        line-height: 30px !important;
                    }
                }
            }
        }

    }

    .grid {
        @include grid-layout;
        align-items: center;
        grid-template-columns: 571px minmax(0, 1fr);
        padding: 0 0 100px 0;
        gap: 0 211px;

        @include breakpoint("max-xl") {
            grid-template-columns: repeat(2, 1fr);
            gap: 0 120px;
        }

        @include breakpoint("max-md") {
            grid-template-columns: repeat(1, 1fr);
            padding: 0 0 40px 0;
            gap: 40px;
        }

        @include breakpoint("max-sm") {
            grid-template-columns: repeat(1, 1fr);
            padding: 0 0 40px 0;
            gap: 40px;
        }

        .gridItems {
            h1 {
                max-width: 460px;

                @include breakpoint("max-xl") {
                    max-width: 420px;
                    @include heading-small-size;
                }

                @include breakpoint("max-sm") {
                    font-size: 22px;
                    line-height: 31px;
                }
            }
        }
    }
}