@import '../../../styles/mixins/global.scss';

.commerceBusinessSection {
    padding: 60px 0 100px 0;

    @include breakpoint("max-xl") {
        padding: 80px 0;
    }

    @include breakpoint("max-md") {
        padding: 0 0 80px 0;
    }

    @include breakpoint("max-sm") {
        padding: 0 0 65px 0;
    }

    .pageTitle {
        max-width: 660px;
        padding: 0 0 map-get($pixel, md) * 11 + 2px 0;

        @include breakpoint("max-xl") {
            padding: 0 0 80px 0;
        }

        @include breakpoint("max-sm") {
            padding: 0 0 30px 0;
        }

        h1 {
            @include text-5xl;
            color: $black-1;
            max-width: 410px;

            @include breakpoint("max-xl") {
                @include heading-small-size;
            }

            @include breakpoint("max-sm") {
                @include heading-6;
                max-width: 235px;
            }

            a {
                @include text-gradient;
            }
        }
    }

    .grid {
        @include grid-layout;
        grid-template-columns: repeat(3, 1fr);
        gap: 0 119px;

        @include breakpoint("max-md") {
            grid-template-columns: repeat(1, 1fr);
            gap: 60px;
        }

        @include breakpoint("max-sm") {
            grid-template-columns: repeat(1, 1fr);
            gap: 46px;
        }

        .gridItems {
            .iconStyle {
                padding: 0 0 map-get($pixel, md) * 5 - 1px 0;

                @include breakpoint("max-sm") {
                    @include flex-center;
                    padding: 0 0 16px 0;
                    justify-content: map-get($layout, center);
                }

                img {
                    max-width: 261px;
                }
            }

            p {
                @include text-xl-normal;
                color: $black-1;
                line-height: 1.7;
                letter-spacing: 0px;

                @include breakpoint("max-sm") {
                    @include text-xl-normal;
                }
            }

            h2 {
                position: relative;
                @include heading-3xl;
                max-width: 378px;
                padding-left: map-get($pixel, md) * 2;
                color: $black-1;
                margin: 0 0 map-get($pixel, sm) * 3 + 1px 0;

                @include breakpoint("max-sm") {
                    margin: 0 0 10px 0;
                }

                &::before {
                    content: "";
                    width: 4px;
                    background-color: #ea7d22;
                    position: absolute;
                    top: 5px;
                    left: 0;
                    height: calc(100% - 10px);

                }
            }
        }

    }
}