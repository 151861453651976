@import '../../../styles/mixins/global.scss';

.WebthreeSection {
    padding: map-get($pixel, md) * 10 0 0 0;

    @include breakpoint("max-xl") {
        padding: 80px 0;
    }

    @include breakpoint("max-md") {
        padding: map-get($pixel, md) * 8 0;
    }

    @include breakpoint("max-sm") {
        padding: map-get($pixel, md) * 6 0;
    }

    .grid {
        @include grid-layout;
        grid-template-columns: minmax(0, 1fr) 623px;
        gap: 0 90px;

        @include breakpoint("max-xl") {
            gap: 0 100px;
        }

        @include breakpoint("max-md") {
            grid-template-columns: repeat(1, 1fr);
            gap: 40px;
        }

        @include breakpoint("max-sm") {
            gap: 12px;
        }

        .gridItems {
            h1 {
                max-width: 631px;
                @include text-5xl;

                @include breakpoint("max-xl") {
                    @include heading-small-size;
                }

                @include breakpoint("max-sm") {
                    @include heading-6;
                    padding-right: 10px;
                }

                a {
                    @include text-gradient;
                }
            }

            p {
                max-width: 623px;
                @include text-xl-normal;
                line-height: 1.9;
                letter-spacing: 0px;
                color: $black-1;

                @include breakpoint("max-xl") {
                    line-height: 32px;
                    max-width: 565px;
                }

                @include breakpoint("max-sm") {
                    @include text-xl-normal;
                    line-height: 29px;
                }
            }
        }
    }
}

.anyTypeVideoShow {
    position: relative;
    width: 100%;
    overflow: hidden;

    video {
        width: 100%;
        object-fit: cover;
        height: auto;
    }
}

.jsonStyleFixed {
    position: relative;

    .visaJsonAlignment {
        div:first-child {
            position: absolute;
            top: 0;
            height: 1100px;
            left: 0%;
        }
    }

    .appleJsonAlignment,
    .venmoJsonAlignment {
        div:first-child {
            position: absolute;
            height: 1100px;
            right: 0%;
            top: 0;
        }
    }

    .storeJsonAlignment {
        div:first-child {
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }

    .terminalJsonAlignment {
        div:first-child {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: -1;
        }
    }
}

.finallyWebThreeAnimation {
    padding: 100px 0 0 0;

    @include breakpoint("max-xl") {
        padding: 0px 0 0 0;
    }

    @include breakpoint("max-sm") {
        padding: 0 0 60px 0;
        display: none;
    }
}

.wrbThreeJsonMobileViewLoad {
    display: none;
    padding: 0 0 60px 0;

    @include breakpoint("max-sm") {
        display: block;
    }
}