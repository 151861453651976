@import '../../../styles/mixins/global.scss';

.physicalTetheringAlign {
    padding: 0 0 120px 0;

    @include breakpoint("max-xl") {
        padding: 80px 0;
    }

    @include breakpoint("max-md") {
        padding: 0 0 80px 0;
    }

    .grid {
        @include grid-layout;
        grid-template-columns: 595px minmax(0, 1fr);
        gap: 0 160px;
        align-items: center;

        @include breakpoint("max-xl") {
            grid-gap: 0 92px;

        }

        @include breakpoint("max-md") {
            grid-template-columns: repeat(1, 1fr);
            gap: 60px;
        }

        @include breakpoint("max-sm") {
            grid-template-columns: repeat(1, 1fr);
            gap: 20px;
        }

        .gridItems {
            h1 {
                @include text-5xl;
                max-width: 450px;
                line-height: 55px;
                margin: 0 0 map-get($pixel, md) * 3 + 6px 0;
                color: $black-2;

                @include breakpoint("max-xl") {
                    @include heading-small-size;
                    max-width: 435px;
                }

                @include breakpoint("max-sm") {
                    @include text-2xl-bold;
                    max-width: 250px;
                    margin: 0 0 20px 0;
                }
            }

            p {
                @include text-xl-normal;
                letter-spacing: 0px;
                color: $black-2;
                line-height: 36px;
                margin: 0;

                @include breakpoint("max-xl") {
                    line-height: 30px !important;
                    max-width: 485px;
                }

                @include breakpoint("max-sm") {
                    line-height: 30px;
                    max-width: 318px;
                    letter-spacing: -0.44px;
                }
            }
        }
    }
}