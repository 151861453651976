@import './colors';
@import '../mixins/typography';

button {
    display: block;
}

.white-button {
    button {
        padding: 4px 12px;
        background-color: $white-color;
        @include text-md-bold;
        border-radius: 999px;
        cursor: pointer;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 177px;
        color: $blue-color;
        height: 44px;
        line-height: normal;
        transition: .3s ease-in-out;
        &:hover {
            transition: .3s ease-in-out;
            transform: translate(0 , -3px);
            background-color: $orange-light-color;
            color: $white-color;
        }
    }
}

.blue-button {
    button {
        padding: 4px 12px;
        background-color: $blue-color-light-400;
        @include text-xl;
        font-size: 18px;
        border-radius: 999px;
        cursor: pointer;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 164px;
        color: $white-color;
        height: 44px;
        line-height: normal;
        transition: .3s ease-in-out;
        &:hover {
            transition: .3s ease-in-out;
            transform: translate(0 , -3px);
            background-color: $orange-light-color;
            color: $white-color;
        }
        @include breakpoint("max-sm") {
            @include text-md-bold;
        }
        &:hover {
            transition: .3s ease-in-out;
            transform: translate(0 , -3px);
        }
    }
}