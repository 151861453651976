@import '../../../styles/mixins/global.scss';

.engageYourAudienceBanner {
    background-image: url("../../../assets/Images/engageYourAudienceBanner.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    padding: 192px 0 183px 0;

    @include breakpoint("max-sm") {
        padding: 157px 0 145px 0;
    }

    h1 {
        @include text-5xl;
        color: $white-color;
        @include text-center;
        font-family: 'Sofia-Medium';
        line-height: 50px;

        @include breakpoint("max-xl") {
            @include heading-small-size;
        }

        @include breakpoint("max-sm") {
            @include heading-3;
            max-width: calc(202px);
            letter-spacing: -0.71px;
            margin: 0 auto;
        }

        br {
            @include breakpoint("max-sm") {
                display: none;
            }
        }

        a {
            @include text-gradient;
        }
    }

    .buttonCenterAlignment {
        @include flex-center;
        padding: map-get($pixel, md) * 3 0 0 0;
        justify-content: map-get($layout, center);

        @include breakpoint("max-sm") {
            padding: 21px 0 0 0;
        }

        button {
            min-width: 186px;
        }
    }
}